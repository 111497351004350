@import "../../../styles/variables";

.cc-select {
  width: 200px;
  padding: 4px 8px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid $white-font;
  color: $white-font;
  font-size: 14px;
}

.cc-select-option {
  background-color: inherit;
  font-size: inherit;
  color: inherit;
}
