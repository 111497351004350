@import "./reset";
@import "./variables";
@import "./animations";

@font-face {
  font-family: "Poppins";
  src: url("../Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../Poppins-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("../Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../public/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Oswald";
  src: url("../../public/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Oswald-Extralight";
  src: url("../../public/Oswald-ExtraLight.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Work-Sans";
  src: url("../../public/WorkSans-Regular.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Work-Sans-Extralight";
  src: url("../../public/WorkSans-Thin.ttf") format("truetype");
  font-weight: normal;
}

$light-theme: "light-mode";

// * DEFAULT CSS VARIABLES =====
:root {
  --alert: #{$alert};
  --warning: #{$warning};
  --action: #{$action};
  --action-alt: #{$alt-action};
  --success: #{$success};
  --alert-prog-bar: #{$alert-prog-bar};
  --accent-ppf-personal: #{$accent-ppf-personal};
  --accent-ppf-professional: #{$accent-ppf-professional};
  --accent-ppf-financial: #{$accent-ppf-financial};

  --white: #{$white};
  --black: #{$black};
  --white-font: #{$white-font};
  --black-font: #{$black-font};
  --red-bell: #{$red-bell};
  --grey-border: #{$grey-border};
  --grey-dark: #{$grey-dark};
  --grey-light-bg: #{$grey-light-bg};
  --nav-height: #{$nav-height};

  --font-color: var(--white-font);
  --border-color: var(--font-color);
  --font-family: "Poppins", sans-serif;
  --font-family2: "Montserrat";
  --font-family3: "Oswald";
  // --font-family: "Montserrat", sans-serif;
  --bg-color: #062029;
  // --bg-color: var(--black);
  --opaque-bg: #{$opaque-background};
  --opaque-bg-inverse: #{$opaque-background-dark};
  --semi-opaque-bg: #{$semi-opaque-background};
  --semi-opaque-bg-inverse: #{$semi-opaque-background-dark};
  --warning-semi-transparent: #{$background-warning-transparent};
  --success-opaque: #{$background-success-transparent};
  --action-opaque: #{$background-action-transparent};
  --alert-opaque: #{$background-alert-transparent};
  // --bg-gradient: linear-gradient(to top, #062029, #062029);
  --bg-gradient: #{$dark-grey-gradient};
  --tile-hover-bg: #{$cv-mid};
  --tile-bg: transparent;
  --tile-info-bg: linear-gradient(to top, var(--bg-color), rgba(0, 0, 0, 0));
  --scrollbar-bg: transparent;
  --scrollbar-bg-hover: var(--opaque-bg);
  --scrollbar-width: 8px;
  --scrollbar-thumb: var(--semi-opaque-bg);
  --focus-outline-color: tomato;
  --context-menu-bg: #29303c;
  --context-menu-border-color: #29303c;
  --context-menu-font-color: var(--white);

  --nav-header-bg: linear-gradient(
      to bottom,
      rgba(23, 32, 42, 0.6),
      rgba(23, 32, 42, 0.6)
    ),
    linear-gradient(to bottom, var(--bg-color), var(--bg-color));
  --menu-bg: #0f1621;
  // --menu-bg: var(--nav-header-bg);
  --menu-width: 275px;
  --bg-gradient-percentile: 85%;
  --app-bg: #062029;
}

body {
  background-color: var(--black);

  @media screen and (min-width: $mobile-top) {
    &::-webkit-scrollbar-track {
      background: var(--opaque-bg);
    }
  }

  // overflow: hidden !important;
  &.no-scroll {
    overflow: hidden !important;

    // position: relative;

    // &::after {
    //   content: "No Scroll";
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    //   padding: 4px;
    //   box-sizing: border-box;
    //   background-color: tomato;
    //   z-index: 10000;
    //   width: 100%;
    // }
  }
}

@media screen and (min-width: 801px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg-hover);
  }

  ::-webkit-scrollbar {
    width: var(--scrollbar-width); /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */

    &:hover {
      background: --scrollbar-bg-hover;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb); /* color of the scroll thumb */
    border-radius: 12px; /* roundness of the scroll thumb */
    border: 2px solid transparent; /* creates padding around scroll thumb */
  }
}

* {
  outline-color: var(--focus-outline-color);
}

.App {
  background: var(--app-bg);
  // background: $transparent-to-black, var(--bg-gradient);
  background-size: cover, 100% 100%, 100% 50%;
  background-repeat: repeat, no-repeat, no-repeat;
  min-height: 100vh;
  background-color: var(--bg-color);
  color: var(--font-color);
  font-family: var(--font-family);
  // background-color: $black;
  // color: $white-font;

  @media screen and (max-width: 800px) {
    --bg-gradient-percentile: 65%;
    --menu-width: clamp(250px, 70vw, 325px);
    --nav-height: 0px;
  }

  &[data-theme="light"] {
    // * Update common variables
    background: var(--white);
    --font-color: var(--black-font);
    --bg-color: var(--white);
    --opaque-bg: #{$opaque-background-dark};
    --opaque-bg-inverse: #{$opaque-background};
    --semi-opaque-bg: #{$semi-opaque-background-dark};
    --semi-opaque-bg-inverse: #{$semi-opaque-background};
    --bg-gradient: #{$grey-gradient};
    --tile-bg: var(--opaque-bg);
    --tile-hover-bg: var(--grey-light-bg);
    --tile-info-bg: linear-gradient(
      to top,
      var(--bg-color) 55%,
      rgba(255, 255, 255, 0)
    );
    --nav-header-bg: var(--bg-color);
    --menu-bg: var(--bg-color);
  }

  // * If value is system and system is light, mimic choosing light theme
  &[data-theme="system"] {
    @media (prefers-color-scheme: light) {
      background: var(--white);
      --font-color: var(--black-font);
      --bg-color: var(--white);
      --opaque-bg: #{$opaque-background-dark};
      --opaque-bg-inverse: #{$opaque-background};
      --semi-opaque-bg: #{$semi-opaque-background-dark};
      --semi-opaque-bg-inverse: #{$semi-opaque-background};
      --bg-gradient: #{$grey-gradient};
      --tile-bg: var(--opaque-bg);
      --tile-hover-bg: var(--grey-light-bg);
      --tile-info-bg: linear-gradient(
        to top,
        var(--bg-color),
        rgba(255, 255, 255, 0)
      );
      --nav-header-bg: var(--bg-color);
      --menu-bg: var(--bg-color);
    }
  }

  @media screen and (min-width: 1350px) {
    --menu-width: 325px;
  }
}

.title-font {
  font-family: var(--font-family);
}

.title-font2 {
  font-family: var(--font-family2);
}

.title-font3 {
  font-family: var(--font-family3);
}

.text-color,
.font-color {
  color: var(--font-color);
}

.border-color {
  border-color: var(--border-color);

  .App.light-mode & {
  }
}

.button-style-reset {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: none;
}

.font-size-title {
  font-size: 34px;
}

.font-size-large {
  font-size: 24px;
}

.font-size-medium {
  font-size: 18px;
}

.font-size-normal {
  font-size: 15px;
}

.font-size-small {
  font-size: 12px;
}

.font-size-tiny {
  font-size: 9px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.fade {
  opacity: 0.5;
}

.fade-max {
  opacity: 0.2;
}

.opaque-bg {
  background-color: var(--opaque-bg);

  .App.light-mode & {
  }
}

.semi-opaque-bg {
  background-color: var(--semi-opaque-bg);

  .App.light-mode & {
  }
}

.button-generic,
.btn-touch-reaction {
  transition: transform 180ms ease-in-out;

  &:active {
    transform: scale(0.96);
  }

  &:disabled:active {
    transform: none;
  }
}

.button-generic {
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.button-anticipation-hover {
  transition: transform 180ms ease-in-out;

  @media screen and (min-width: $mobile-top) {
    &:hover {
      transform: scale(1.06);
    }
  }
}

.interface-wrapper {
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: grid;
  grid-template-columns: var(--menu-width, 250px) 1fr;
  grid-template-rows: var(--nav-height) 1fr;
  grid-template-areas: "main main" "main main";
  // grid-template-areas: "nav nav" "main main";
  // overflow-x: hidden; // ! Breaks 'position: sticky;'

  &.menu-open {
    grid-template-areas: "menu main" "menu main";
    // grid-template-areas: "menu nav" "menu main";
    @media screen and (max-width: $mobile-breakpoint) {
      overflow: hidden;
    }
  }

  // .App[data-hasparent="true"] &.menu-open {
  //   grid-template-areas: "nav main" "menu main";
  // }

  @media screen and (max-width: $mobile-breakpoint) {
    // overflow: hidden !important;
    &.no-scroll {
      overflow: hidden !important;
    }
  }
}

.interface-main-cont {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  min-height: calc(
    100vh - 70px
  ); // ! Needs to be updated to account for header so not perma overflow-Y scroll
  overflow-x: hidden;

  .interface-wrapper > & {
    min-height: unset;
    grid-area: main;

    // .App[data-hasparent="true"] & {
    //   grid-area: 1 / 1 / 3 / 3;
    // }

    @media screen and (max-width: $mobile-breakpoint) {
      grid-area: 1 / 1 / 3 / 3;
    }
  }

  .interface-wrapper.menu-open > & {
    min-height: unset;
    grid-area: main;

    // .App[data-hasparent="true"] & {
    //   grid-area: main;
    // }

    @media screen and (max-width: $mobile-breakpoint) {
      grid-area: 1 / 1 / 3 / 3;
    }
  }

  .interface-wrapper.menu-open > & {
    max-width: calc(100vw - var(--menu-width, 250px));
    animation: adjustBodyFromLeft 480ms ease-in-out;

    @media screen and (max-width: $mobile-breakpoint) {
      animation: none;
      // animation-name: adjustBodyFromLeftMobile;
    }

    @media (prefers-reduced-motion) {
      animation: none;
      // animation-name: fadeIn;
    }
  }

  .interface-wrapper:not(.menu-open) > &.animate-back {
    animation: adjustBodyToLeft 480ms ease-in-out;

    @media screen and (max-width: $mobile-breakpoint) {
      animation: none;
    }

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100vw;
    min-width: 100vw;

    // overflow: hidden !important;
    &.no-scroll {
      overflow: hidden !important;
    }
  }
}

.centered-content-wrapper {
  margin: 0px auto;
  width: 100%;
  max-width: 1000px;
  // max-width: 1200px;

  @media screen and (max-width: $mobile-breakpoint) {
    // padding: 0px 12px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
  }
}

.input-seemless {
  margin: 0px;
  padding: 3px 0px;
  box-sizing: border-box;
  border: none;
}

.flex-col-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-underline {
  // --_theme: var(--action);

  position: relative;

  &.personal {
    // --_theme: var(--);
  }
  &.professional {
    // --_theme: var(--);
  }

  &.financial {
    // --_theme: var(--);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 100%;
    height: 2px;
    background-color: var(--_theme);
    animation: growFromLeft 180ms ease-in-out;
  }
}

.loading-blink {
  color: transparent;
  background-color: var(--grey-dark);
  animation: blink 1200ms linear infinite;
}
