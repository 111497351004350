@import "../../../styles/variables";

// .cg-goalitems-goal-items-list {
//   padding-top: 30px;
// }

.cg-goalitems-item-cont {
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.cg-goalitems-item-checkbox {
}

.cg-goalitems-item-input {
  box-sizing: border-box;
  flex-grow: 1;
  max-width: calc(100% - 33px);

  @media screen and (min-width: $mobile-top) {
    font-size: 18px;
  }
}

.cg-goalitems-item-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 13px;
  }
}
