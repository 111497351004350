@import "../../../../styles/animations";

.profile-tabs-dataitem {
  padding: 12px 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  animation: fadeIn 600ms ease-in-out;

  &.has-change {
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.profile-tabs-dataitem-label-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 19px;

  .has-change & {
    color: var(--warning);
  }

  @media screen and (max-width: 800px) {
    flex-basis: 100%;
    justify-content: flex-start;
    font-size: 14px;
  }
}

.profile-tabs-dataitem-label {
  width: 175px;
  color: var(--semi-opaque-bg);
  font-size: inherit;
}

.profile-tabs-dataitem-input-cont {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    margin-top: 12px;
    flex-basis: 100%;
  }
}

.profile-tabs-dataitem-input {
  padding: 10px 0px;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid var(--semi-opaque-bg);
  border-radius: 0px;
  width: 100%;
  max-width: 540px;
  font-size: 19px;

  &:active,
  &:focus {
    outline: none;
    border-bottom-color: var(--font-color);
  }

  // .has-change & {
  //   border-bottom-color: var(--warning);
  // }

  // .unset-value & {
  //   border-bottom-color: var(--warning);
  // }
}

.profile-tabs-dataitem-select {
  .has-change & {
    // border-color: var(--warning);
  }

  &.unset-value {
    // color: var(--warning);
    // border-color: var(--warning);
  }
}

.profile-tabs-dataitem-unset-timezone-msg {
  padding-left: 12px;
  // color: var(--warning);
  font-style: italic;

  @media screen and (max-width: 800px) {
    padding-left: 0px;
    padding-top: 6px;
    flex-basis: 100%;
  }
}

.profile-tabs-data-undo-btn {
  position: absolute;
  right: 0px;
  top: 12px;
  // fill: var(--warning);
}
