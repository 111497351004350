.interface-ppfplans {
  padding: 21px 12px 40px;
  box-sizing: border-box;
  height: 100%;
  box-sizing: border-box;
}

.plans-main-nav {
  padding-bottom: 0px;
}

.plans-list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plans-list-item {
  padding: 0px;
  box-sizing: border-box;
  width: 100%;

  &:nth-of-type(n + 2) {
    padding-top: 10px;
    border-top: 1px solid var(--opaque-bg);
  }
}

.plans-list-item-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: var(--font-color);
  text-decoration: none;
}

.plans-list-item-link-right-cont {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.plans-list-item-link-plan-name {
}

.plans-list-item-link-user-name {
  font-size: 12px;
}
