@import "../../../styles/variables";

.cg-goals-stories {
  display: flex;
  flex-direction: column;
  height: 100%;

  // * TEST
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  grid-template-rows: minmax(40px, auto) 1fr;
  grid-template-areas:
    "button-left directory-nav button-right"
    "button-left current-goal button-right";

  &.has-manager-msg {
    grid-template-columns: 60px 1fr 60px;
    grid-template-rows: minmax(100px, auto) minmax(100px, auto) 1fr;
    grid-template-areas:
      "become-manager become-manager become-manager"
      "button-left directory-nav button-right"
      "button-left current-goal button-right";

    // .goal-nav-section-cont {
    //   display: none;
    // }

    @media screen and (max-width: $mobile-breakpoint) {
      grid-template-columns: 40px 1fr 40px;
      grid-template-rows: minmax(10px, auto) minmax(40px, auto) 1fr;
      grid-template-areas:
      "become-manager become-manager become-manager"
        "button-left directory-nav button-right"
        "current-goal current-goal current-goal";
    }
  }

  &.has-sts-alert {
    grid-template-rows: minmax(100px, auto) auto 1fr;
    grid-template-areas:
      "button-left directory-nav button-right"
      "button-left status-alert button-right"
      "button-left current-goal button-right";

    @media screen and (max-width: $mobile-breakpoint) {
      grid-template-areas:
        "button-left directory-nav button-right"
        "status-alert status-alert status-alert"
        "current-goal current-goal current-goal";
    }
  }

  &.goalsgrid-display {
    grid-area: 1 / 1 / 2 / 2;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "goals-grid";

    @media screen and (max-width: $mobile-breakpoint) {
      grid-area: 1 / 1 / 2 / 2;
      grid-template-areas: "goals-grid";
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    grid-template-columns: 40px 1fr 40px;
    grid-template-rows: minmax(40px, auto) 1fr;
    grid-template-areas:
      "button-left directory-nav button-right"
      "current-goal current-goal current-goal";
  }
}

#goal-stories-become-manager {
  grid-area: become-manager;
}
#goal-stories-dir {
  grid-area: directory-nav;
}
#goal-stories-btn-left {
  grid-area: button-left;
}
#goal-stories-btn-right {
  grid-area: button-right;
}
#goal-stories-warning-msg {
  grid-area: status-alert;
}
#goal-stories-curgoal {
  grid-area: current-goal;
}

.cg-goals-stories-btn-cont {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $mobile-breakpoint) {
    justify-content: center;
  }
}

.cg-goals-stories-left-btn,
.cg-goals-stories-right-btn {
  margin-top: 250px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 0px;
  }
}

.cg-goals-stories-notfound-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.cg-goals-stories-notfound-section-title {
  margin: 10% 0% 20% 0%;
  text-align: center;
  padding-bottom: 20px;
  font-size: 1em;
}

.cg-goals-stories-notfound-section-btn-link {
  width: 100%;
  text-decoration: none;
}

.cg-goals-stories-notfound-section-btn {
  margin: 0px auto;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.cg-goals-stories-warning-cont {
  --_margin-top: 0px;

  margin-bottom: 23px;
  margin-top: var(--_margin-top);
  padding: 23px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--warning);
  background-color: var(--opaque-bg);
  display: flex;
  flex-direction: row;
  gap: 13px;
  animation: fadeInGrow 700ms ease-in-out;

  @media screen and (max-width: $mobile-breakpoint) {
    --_margin-top: 23px;
  }
}

@keyframes fadeInGrow {
  0% {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
  }
  50% {
    margin-top: var(--_margin-top);
    margin-bottom: 23px;
    padding: 23px;
    opacity: 0;
    max-height: 90px;
  }
  100% {
    opacity: 1;
  }
}

.cg-goals-stories-warning-icon-cont {
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cg-goals-stories-warning-icon {
  fill: var(--warning);
}

.cg-goals-stories-warning-msg-cont {
  flex-grow: 1;
}

.cg-goals-stories-warning-msg {
  font-size: 16px;
  line-height: 21px;
  color: var(--warning);
}

.cg-manager-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 30px;
  box-sizing: border-box;
  // justify-content: right;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: 30px;
  }
}

.cg-manager-add-btn {
  // margin: -70px auto auto auto;
  margin: auto;
  justify-content: center;
  @media screen and (max-width: 821px) {
    margin: auto;
  }
}
