.cc-search-input-wrapper {
  position: relative;
  background-color: var(--opaque-bg);
  border-radius: 10px;
}

.cc-search-input {
  padding: 12px 30px 12px 42px;
  background-color: var(--transparent);
  border-radius: inherit;
  border: none;
  width: 100%;
  // border-color: var(--opaque-bg);
}

//* Styles are mobile by default
.cc-search-input-eyeglass {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  // left: 35px;
  // top: 75px; //only works assuming 70px is not on top otherwise needs changed to 145px
}

// .cc-search-icon-shift {
//   padding-left: 40px;
// }

.cc-search-input-clear-icon-btn {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

// .cc-search-input-clear-icon {
// vertical-align: middle;
// margin-bottom: 5px;
// margin-left: -35px;
// }
