// @import "../../../styles/colors";

// mark {
// background-color: #f9e79f;
// }

.cc-texthightlighter-token {
  &.token-mark {
    border-radius: 3px;
    background-color: rgba(246, 155, 30, 0.5);
    color: var(--text-color);
  }
}
