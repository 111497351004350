@import "../../styles/variables";

.interface-home {
  padding-bottom: 40px;
  box-sizing: border-box;
  z-index: 6;
  //   background-color: $black;

  @media screen and (max-width: 800px) {
    // min-width: 100vw;
    max-width: 100vw;
  }
}

.home-nav,
.home-menu {
  z-index: 7;
}

.home-inner-cont {
  margin: 0px auto;
  // padding: 12px;
  // padding-bottom: 60px;
  box-sizing: border-box;
  width: 100%;
  // max-width: 1200px;

  @media screen and (max-width: 800px) {
    padding: 12px 0px;
    width: 100vw;
  }
}
