@import "../../../../styles/variables";

.plan-newgoal-scopeselection-form {
  //   --_theme: transparent;

  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //   gap: 23px;
  height: 100%;
  flex-grow: 1;

  //   &.personal {
  //     --_theme: var(--accent-ppf-personal);
  //   }

  //   &.professional {
  //     --_theme: var(--accent-ppf-professional);
  //   }

  //   &.financial {
  //     --_theme: var(--accent-ppf-financial);
  //   }
}

.plan-newgoal-parentgoals-cont {
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    gap: 23px;
  }
}

.plan-newgoal-parentgoals-goal-input,
.plan-newgoal-goal-length-input {
  margin: 0px;
  padding: 0px;
  opacity: 0;
  height: 0px;
  width: 0px;
  position: absolute;
}

.plan-newgoal-parentgoals-goal-label {
  width: calc(100% / 3);

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
  }
}

.plan-newgoal-parentgoals-goal-label,
.plan-newgoal-goal-length-label {
  --_border-color: transparent;

  &.is-selected {
    --_border-color: var(--_theme);

    // &.personal {
    // 	--_border-color: var(--accent-ppf-personal);
    // }

    // &.professional {
    // 	--_border-color: var(--accent-ppf-professional);
    // }

    // &.financial {
    // 	--_border-color: var(--accent-ppf-financial);
    // }
  }
}

.plan-newgoal-goal-length-label {
}

.plan-newgoal-parentgoals-goal-label-inner-cont {
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  background-color: var(--opaque-bg);
  outline: 2px solid var(--_border-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 238px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  aspect-ratio: 313 / 238;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 12px;
    height: 119px;
    aspect-ratio: 368 / 119;
  }
}

.plan-newgoal-parentgoals-goal-label-inner-name {
  padding: 3px 0px;
  box-sizing: border-box;
  color: var(--white);
  //   border-bottom: 2px solid var(--_border-color);
}

.plan-newgoal-goal-lengths-cont {
  margin-top: 60px;
}

.plan-newgoal-goal-lengths-title {
  font-size: 16px;
  font-weight: bold;
}

.plan-newgoal-goal-lengths-wrapper {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}

.plan-newgoal-goal-length-label-inner-cont {
  padding: 12px;
  border-radius: 15px;
  width: 100%;
  background-color: rgba(66, 66, 66, 0.5);
  border: 2px solid var(--_border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  flex-basis: 80px;
  aspect-ratio: 1 / 1;
}

.plan-newgoal-goal-length-label-inner-name {
  font-size: 30px;
  color: var(--white);
}

.plan-newgoal-scopeselection-form-btn-cont {
  margin-top: auto;
  padding-top: 55px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: 20px;
  }
}

.plan-newgoal-scopeselection-form-btn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
}

.plan-newgoal-parentgoals-goal-label-inner-details {
  margin-top: 12px;
  font-size: 16px;
  color: var(--white);
  animation: slideUp 380ms ease-in-out;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 10px;
    font-size: 15px;
  }
}

.plan-newgoal-parentgoals-goal-label-inner-details-subtitle {
  font-size: 16px;
  color: inherit;
  text-transform: uppercase;
  font-weight: bold;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 14px;
  }
}

.plan-newgoal-parentgoals-goal-label-inner-details-desc {
  padding-top: 3px;
  box-sizing: border-box;
  font-size: 14px;
  color: inherit;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 13px;
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    margin-bottom: -80px;
  }
  70% {
    opacity: 1;
  }
  100% {
    margin-bottom: 0px;
  }
}
