.cc-datepicker {
  padding: 5px 8px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: var(--white);
  color: var(--black-font);
  width: 175px;

  &[data-error="true"] {
    border-color: red;
    background-color: rgba(148, 49, 38, 0.4);
    color: red;

    &:focus {
      outline-color: red;
    }
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.05);
    border-color: transparent;
  }

  .App[data-theme="dark"] &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}
