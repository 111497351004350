@import "../../../styles/variables";

.cg-editgoal-cont {
  width: 100%;
  padding: 0px 23px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  height: 100%;
}

.plan-editgoal-image-and-steps-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 23px;
  font-size: 16px;
  color: white;

  .plan-editgoal-section-title {
    font-family: var(--font-family2);
    font-weight: 900;
    font-size: 26px;
    line-height: unset;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 16px;
    }
  }

  @media screen and (min-width: $mobile-top) {
    flex-direction: row;
    gap: 42px;

    // .cg-editgoal-img-cont,
    // .plan-editgoal-section {
    //   width: 50%;
    // }

    .cg-editgoal-img-cont {
      overflow: hidden;
      border-radius: 15px;
      aspect-ratio: 368 / 525;
      // height: auto;
      min-height: calc(525px * 0.8);
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
  }
}

.plan-editgoal-section {
  width: 100%;
}

.plan-editgoal-section-title {
  font-weight: 400;
  font-size: 14.5572px;
  line-height: 17px;
  display: flex;
  margin-bottom: 10px;

  @media screen and (max-width: $mobile-breakpoint) {
    justify-content: space-between;
    font-size: 10px;
    height: 13px;
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.cg-goalstories-currentgoal-legacy-steps {
  margin-top: 12px;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--opaque-bg);
  width: 100%;
}

.cg-goalstories-currentgoal-legacy-steps-title {
  font-size: 18px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
  }
}

.cg-goalstories-currentgoal-legacy-steps-msg {
  font-size: 13px;
  padding: 12px 0px;
  box-sizing: border-box;
  color: var(--warning);
}

.cg-goalstories-currentgoal-legacy-steps-renderbox {
  width: 100%;
}

.cg-goalstories-currentgoal-legacy-steps-title-btns-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.cg-goalstories-currentgoal-legacy-steps-btns-right-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 13px;
}

.cg-goalstories-currentgoal-legacy-steps-btns-autogen-btn {
  text-decoration: underline;

  &:disabled {
    opacity: 0.4;
  }
}

.detail-fake-input {
  text-align: left;
  color: inherit;
}

.plan-editgoal-section-input-desc.detail-fake-input,
.plan-editgoal-section-input {
  padding: 0px;
  padding-left: 3px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  background-color: var(--transparent);
  font-style: normal;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;

  .plan-editgoal-section-title-input &::placeholder {
    opacity: 0.6;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
  }
}

.textedit-modal-textfield-cont,
.plan-editgoal-section-desc-input {
  &::after {
    padding: 4px;
  }
}

.plan-editgoal-section-input-desc {
  &.detail-fake-input,
  .plan-editgoal-section-desc-input & {
    border: 1px solid #ffffff;
    margin-top: 0px;
    height: 100%;
    line-height: inherit;
    word-break: break-all;
    white-space: pre-wrap;
    font-size: inherit;
    font-family: inherit;
    padding: 4px;
  }

  &.detail-fake-input {
    width: 100%;
    background-color: transparent;
  }
}

.plan-editgoal-section-title-input {
  font-size: 20px;
  padding: 8px 5px;
  font-weight: 400;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 4px;
    font-size: 14px;
    // height: 18px;
  }
}

.plan-editgoal-section-title-input {
  // --_textarea-border-width: 1px;

  .plan-editgoal-section-title-input,
  &::after {
    border: 1px solid #ffffff;
  }
}

.plan-editgoal-section-desc-input {
  font-size: 18px;
  font-weight: normal;

  .plan-editgoal-section-input-desc,
  &::after {
    border: 1px solid #ffffff;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: var(--input-text-size);
    line-height: var(--input-line-height);
    // font-size: 12px;
    // line-height: 14px;
  }

  // @media screen and (min-width: $mobile-top) {
  //   padding-top: 10px;
  // }
}

// .plan-editgoal-save-btn {
//   margin-top: auto;
//   height: 50px;
//   border-radius: 10px;
//   font-size: 16px;

//   @media screen and (min-width: $mobile-top) {
//     margin-top: 32px;
//   }
// }

.plan-editgoal-save-error-msg {
  margin: 0px;
  padding: 12px 0px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: bold;
  color: va(--warning);
}

.plan-editgoal-topbar-years {
  // padding-left: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 11px;
  font-size: 11px;
}

.plan-editgoal-topbar-years-year {
  font-size: inherit;
}

.plan-editgoal-section-SMART {
  display: flex;
  width: fit-content;
  font-size: 11px;
  align-items: center;
  margin-left: 20px;
  font-weight: normal;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-left: 0px;
    font-size: 10px;
  }
}

.plan-editgoal-section-SMART-icon {
  margin-right: 4px;
}

.plan-editgoal-section-required {
  color: var(--alert);
  margin-left: 10px;
  font-size: 18px;
}

.plan-editgoal-top-section {
  display: flex;
}

.plan-editgoal-inputs {
  --input-text-size: 14px;
  --input-line-height: 16px;

  margin-left: 40px;
  width: 100%;

  @media screen and (max-width: $mobile-breakpoint) {
    max-width: 50%;
    margin-left: 10px;
  }
}

.plan-editgoal-section-desc {
  margin-top: 30px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 10px;
  }
}

.plan-editgoal-inputs-dropdowns {
  display: flex;
  margin-top: 30px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 10px;
    display: unset;
  }
}

.plan-editgoal-inputs-dropdown {
  & &__control {
    background-color: transparent;
    color: var(--font-color);
    border: 1px solid #ffffff;
    width: 190px;
    font-size: 20px;

    &:focus {
      border-color: var(--focus-outline-color);
    }

    &:hover {
      border-color: var(--focus-outline-color);
    }

    @media screen and (max-width: 500px) {
      height: 24px;
      max-height: 24px;
      min-height: unset;
      width: 110px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu {
    background-color: var(--bg-color);
    color: var(--font-color);
    height: fit-content;
    width: 160px;
    border: none;

    @media screen and (max-width: 500px) {
      margin: 0px;
      padding: 0px;
      width: 110px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu-option,
  & &__option {
    background-color: transparent;
    color: var(--font-color);

    @media screen and (max-width: 500px) {
      height: 16px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__single-value {
    color: var(--font-color);
    overflow: visible;
    margin-top: 2px;
  }

  & &__placeholder {
    margin-top: 0px;
    color: var(--font-color);
  }

  & &__dropdown-indicator {
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
    box-sizing: border-box;

    .list-showing & {
      transform: rotate(180deg);
    }

    @media screen and (max-width: $mobile-breakpoint) {
      // margin-top: -5px;
      padding: 0px;
    }
  }

  & &__indicator-seperator {
    width: 0px;
  }

  & &__indicators-container {
    @media screen and (max-width: $mobile-breakpoint) {
      height: var(--input-font-size, 14px);
    }
  }

  & &__value-container {
    padding: 0px 0px 4px 4px;
    // padding: 6px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    min-height: 22px;
    box-sizing: border-box;

    @media screen and (min-width: $mobile-top) {
      margin-top: 2px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__input-container {
    margin: 0px;
    max-width: 5px;
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & &__input {
    max-width: 5px;
  }
}

.plan-editgoal-inputs-dropdowns-connected {
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 10px;
  }
}

.plan-editgoal-inputs-dropdown {
  margin-top: 10px;
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-transform: uppercase;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 5px;
  }
}

.plan-editgoal-inputs-dueDate-toggle {
  margin-left: 10px;
}

.plan-editgoal-inputs-dueDate {
  display: flex;
  margin-top: 30px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 10px;
    align-items: center;
  }
}

.plan-editgoal-input-dueDate-picker {
  font-weight: 600;
  margin-top: 0px;
  border: 1px solid var(--white);
  border-radius: 5px;
  background-color: transparent;
  color: var(--white);

  &:disabled {
    border: 1px solid var(--black-font);
    color: var(--black-font);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    // height: 14px;
    // font-size: 11px;
    font-size: var(--input-text-size);
    width: 140px;
    border-radius: 2px;
  }
}

.plan-editgoal-image-wrapper {
  height: unset;
  width: 270px;
  min-width: 270px;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 45%;
    min-width: 45%;
  }
}

.plan-editgoal-image {
  aspect-ratio: 270 / 371;
  // height: unset;
  // width: 270px;
  // min-width: 270px;

  // @media screen and (max-width: $mobile-breakpoint) {
  //   width: 45%;
  //   min-width: 45%;
  // }
}

.plan-editgoal-steps-desc {
  margin-top: 7px;
  font-weight: 400;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10px;
  }
}

.cg-editgoal-goal-step-weight {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: rgba(255, 255, 255, 0.5) solid 1px;
  border-bottom: rgba(255, 255, 255, 0.5) solid 1px;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.cg-editgoal-goal-step-weight-items {
  display: flex;
  font-weight: normal;
  min-height: 26px;

  @media screen and (max-width: $mobile-breakpoint) {
    min-height: 17px;
  }
}

.cg-editgoal-goal-step-weight-item {
  background-color: var(--action);
  // background-color: #0290ff50;
  // filter: brightness(80%);
  // back
  box-sizing: border-box;
  border: 2px solid var(--action);
  border-radius: 20px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 80ms ease-in-out;

  &.complete {
    filter: unset;
    background-color: var(--action);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    height: 17px;
    font-size: 13px;
  }
}

.cg-editgoal-goal-step-weight-item-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-sizing: border-box;
  backdrop-filter: brightness(0.4);
  transition: backdrop-filter 180ms ease-in-out;

  .cg-editgoal-goal-step-weight-item.complete & {
    backdrop-filter: none;
  }
}

.cg-editgoal-goal-step-weight-button {
  // margin-bottom: 2px;
  height: 25px;

  @media screen and (max-width: $mobile-breakpoint) {
    height: 14px;
  }
}

.cg-editgoal-goal-step-weight-percent {
  display: flex;
  margin-bottom: 10px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 5px;
  }
}

.cg-editgoal-goal-step-weight-percent-value {
  font-weight: 600;
  font-size: 26px;
  line-height: unset;
  margin-right: 10px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 16px;
    margin-right: 5px;
  }
}

.cg-editgoal-goal-step-weight-percent-text {
  font-weight: 400;
  font-size: 14.5572px;
  line-height: 17px;
  align-self: flex-end;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10px;
  }
}

.plan-editgoal-buttons {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  gap: 4%;

  button {
    font-weight: 900;
    font-size: 22px;
    height: 50px;
    width: 50%;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      height: 32px;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 15px 30px 30px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: var(--bg-color);
  }
}

.plan-editgoal-add-step {
  border: var(--white) 1px solid;
  border-radius: 21px;
  padding: 2px;
  margin-right: 20px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-right: 8px;
    margin-top: 2px;
  }
}

.plan-editgoal-add-step-section {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14.5572px;
  line-height: 17px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10px;
  }
}
