@import "../../../styles/variables";

.imagepicker-modal-dialog-container {
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--bg-color);
  color: var(--font-color);

  @media screen and (min-width: $mobile-top) {
    height: 900px;
    max-height: 90vh;
    width: 750px;
  }
}

.imagepicker-modal-inner-cont {
  padding-top: 36px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--font-color);

  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: 26px;
    width: 96%;
    margin-left: auto;
  }
}

.imagepicker-modal-title {
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 26px;
  color: var(--font-color);
}

.modals-imagecropper-imagegrid-btn-cont,
.modals-imagecropper-cropper-btn-cont {
  margin-top: 0px;
  padding: 23px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;

  @media screen and (min-width: $mobile-top) {
    padding-bottom: 10px;
  }
}

.modals-imagecropper-imagegrid-btn-cont {
  flex-grow: 1;

  @media screen and (min-width: $mobile-top) {
    padding-bottom: 10px;
  }
}

.modals-imagecropper-imagegrid-btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 50px;
  font-size: 16px;
}
