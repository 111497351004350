.cc-checkbox-input {
}

.cc-checkbox-label {
}

.cc-checkbox-btn {
  --checkbox-bg-color: transprent;

  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 1px solid var(--semi-opaque-bg);
  border-radius: 50%;
  background-color: var(--checkbox-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;

  &[data-checked="true"] {
    --checkbox-bg-color: var(--action);

    &.personal {
      --checkbox-bg-color: var(--accent-ppf-personal);
    }

    &.professional {
      --checkbox-bg-color: var(--accent-ppf-professional);
    }

    &.financial {
      --checkbox-bg-color: var(--accent-ppf-financial);
    }

    &.personal,
    &.professional,
    &.financial {
      @media screen and (max-width: 500px) {
        --checkbox-bg-color: #e9e9e9;
      }
    }
  }
}

.cc-checkbox-btn-icon {
  fill: var(--bg-color);
}
