.plan-disabled-msg-cont {
	margin-bottom: 12px;
	padding: 8px 12px;
	box-sizing: border-box;
	width: 100%;
	border-radius: 5px;
	color: var(--alert);
	border: currentColor;
	border: 1px solid currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
}

.plan-disabled-msg {
	color: inherit;
	font-style: 14px;
}
