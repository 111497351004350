@import "../../../../styles/variables";

.plan-parentgoal-list {
  --_theme: var(--opaque-bg);

  display: flex;
  gap: 23px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  &.personal {
    --_theme: var(--accent-ppf-personal);
  }

  &.professional {
    --_theme: var(--accent-ppf-professional);
  }

  &.financial {
    --_theme: var(--accent-ppf-financial);
  }

  & + .plan-parentgoal-list {
    margin-top: 23px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
}

.plan-childgoal-card-link-wrapper {
  margin: 0px;
  padding: 0px;
  display: block;
  width: 100%;
  min-width: 300px;
  flex-grow: 1;

  max-width: calc((100% / 3) - (46px / 1.5));
  text-decoration: none;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 100%;
  }
}

.plan-childgoal-card {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  background-color: var(--opaque-bg);
  color: var(--_theme);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  aspect-ratio: 265 / 119;

  @media screen and (max-width: $mobile-breakpoint) {
    aspect-ratio: 368 / 119;
  }
}

.plan-childgoal-name {
  font-size: 16px;
}

.plan-childgoal-summary {
  font-size: 12px;
}
