@import "../../../../../styles/variables";

.modals-imagecropper-cropper-cont {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.modals-imagecropper-cropper-wrapper {
  padding: 12px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: calc(100% - 110px);

  @media screen and (min-width: $mobile-top) {
    max-height: calc(100% - 97px);
  }
}

.modals-imagecropper-cropper-title {
  width: 100%;
  padding: 8px 0px;
  font-size: 14px;
}

.modals-imagecropper-cropper-loading-msg {
  text-align: center;
  margin: auto;

  .cc-message {
    font-size: 24px;
  }
}

.modals-imagecropper-cropper-square {
}

.modals-imagecropper-cropper-landscape {
}

.modals-imagecropper-cropper-portrait {
}

.modals-imagecropper-cropper-wrapper-cont {
  margin: auto;
  max-height: 100%;
}

.modals-imagecropper-cropper-cropper {
  .modals-imagecropper-cropper-wrapper-cont & {
    max-height: 100%;
  }
}
