.cg-editgoal-img-cont {
  width: 100%;
  // height: 119px;
  border-radius: 15px;
  background-color: var(--opaque-bg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  aspect-ratio: 270 / 371;
  // aspect-ratio: 368 / 119;

  &.unacceptable-file-type {
    outline: 2px solid var(--alert);
  }

  &.acceptable-file-type {
    outline: 2px solid var(--success);
  }
}

.cg-goaledit-img-input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 3;
}

.cg-editgoal-img-change-btn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: backdrop-filter 240ms ease-in-out;
  z-index: 2;

  &:hover {
    cursor: pointer;

    .has-image & {
      background: linear-gradient(
        to top left,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.2)
      );
      backdrop-filter: blur(2px);
    }
  }
}

.cg-editgoal-img-remove-icon-btn {
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  z-index: 2;

  &:hover {
    background: radial-gradient(var(--black), transparent);
  }
}

.cg-editgoal-img-remove-icon {
}
