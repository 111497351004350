.profileimage-modal-dialog-container {
  background-color: var(--white);
}

.profileimage-modal-inner-cont {
  padding-top: 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.profileimage-modal-title {
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 26px;
  color: var(--black-font);
}

.profileimage-modal-tabs {
  padding: 0px 0px 6px;
  width: 100%;
}

.profileimage-modal-tabs-btn {
  margin-right: 8px;
  padding-left: 1px;
  padding-right: 1px;
  color: var(--black-font);
  font-size: 14px;
  border-bottom: 1px solid transparent;

  &:disabled {
    opacity: 0.4;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:not(.current-tab):not(:disabled):hover {
    border-bottom: 1px solid var(--black-font);
  }

  &.current-tab {
    color: var(--action);
    border-bottom: 1px solid var(--action);
  }
}

.profileimage-modal-file-input {
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &.profileimage-modal-file-input-hidden {
    display: none;
  }
}

.profileimage-modal-preview-cont {
  padding: 10px 0px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 75%;
  max-height: 75%;
  flex-grow: 1;
}

.profileimage-modal-preview-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.profileimage-modal-preview-reset-button {
  margin: 6px 0px;
  color: var(--action);
}

.profileimage-modal-inner-row-cont {
  padding-top: 20px;
  box-sizing: border-box;
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.profileimage-modal-inner-buttons-button {
  min-height: 35px;
}

.profileimage-modal-inner-images-cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 75%;
  overflow-y: auto;
}

.profileimage-modal-inner-images-image-cont {
  padding: 6px;
  box-sizing: border-box;
  flex-basis: 50%;
  max-height: 150px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;

  &.file-input-wrapper {
    max-height: 275px;
    height: 275px;
  }

  &.profileimage-modal-inner-images-image-cont-selected {
    background-color: var(--semi-opaque-bg-inverse);
  }
}

.profileimage-modal-inner-images-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 132px;
}
