@import "../../../../../styles/variables";

.cg-goalstories-currentgoal-cont {
  --_anim_translate_x_start: 50px;
  --_anim_start_scale: 0.95;

  padding-top: 12px;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.grow-in {
    animation: growIn 260ms ease-in-out;
  }

  &.from-right {
    // --_anim_translate_x_start: 30px;
    animation: fromRight 260ms ease-in-out;
  }

  &.from-left {
    --_anim_translate_x_start: -50px;
    animation: fromRight 260ms ease-in-out;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    --_anim_translate_x_start: 30px;

    padding-top: 30px;
    padding-bottom: 120px;

    &.from-left {
      --_anim_translate_x_start: -30px;
    }
  }
}

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(var(--_anim_start_scale, 0.95));
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(var(--_anim_scale, 1));
  }
}

@keyframes fromRight {
  0% {
    --_chat-display: none;

    opacity: 0;
    transform: translateX(var(--_anim_translate_x_start, 30px));
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

.cg-goalstories-currentgoal-title-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: $mobile-breakpoint) {
    justify-content: space-between;
    gap: 12px;
  }
}

.cg-goalstories-currentgoal-title-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;

  @media screen and (max-width: $mobile-breakpoint) {
    justify-content: space-between;
    gap: 12px;
  }
}

.cg-goalstories-currentgoal-title-btn {
  margin-bottom: -7px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: -4px;
  }
}

.cg-goalstories-currentgoal-ctx-menu {
  margin: 0px 0px auto;
  padding: 0px;
  box-sizing: border-box;
  border: none;
  background-color: var(--transparent);
  // position: absolute;
  // left: 220px;

  @media screen and (max-width: $mobile-breakpoint) {
    left: 140px;
  }
}

.cg-goalstories-currentgoal-desc {
  width: 100%;
  font-family: "Oswald";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  overflow-wrap: anywhere;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-bottom: 2px;
    font-size: 15px;
    line-height: 20px;
  }
}

.cg-goalstories-currentgoal-imgsteps-cont {
  margin-top: 34px;
  width: 100%;
  background-color: var(--opaque-bg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  border-radius: 15px;
  aspect-ratio: 368 / 525;
  min-height: calc(525px * 0.8);
  display: flex;
  flex-direction: column;

  &.no-image {
    outline: 2px solid var(--alert);
  }

  @media screen and (max-width: 800px) {
    min-height: calc((100vw - 24px) * 1.42);
  }
  // aspect-ratio: 368 / 477;
}

.cg-goalstories-currentgoal-imgsteps-no-img-icon,
.cg-goalstories-currentgoal-imgsteps-no-img-msg {
  display: none;

  .cg-goalstories-currentgoal-imgsteps-cont.no-image & {
    display: initial;
  }
}

.cg-goalstories-currentgoal-imgsteps-no-img-msg {
  padding-top: 8px;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--alert);
}

.cg-goalstories-currentgoal-desktop-data-inner-prog-cont {
  height: 100%;
}

.cg-goalstories-currentgoal-imgsteps-details-cont {
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 32, 41, 0.75);
  max-height: 90%;
  overflow-y: auto;

  @media screen and (min-width: $mobile-top) {
    height: 100%;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cg-goalstories-currentgoal-imgsteps-details-toggles-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cg-goalstories-currentgoal-imgsteps-details-cont::-webkit-scrollbar {
  display: none;
}

.cg-goalstories-currentgoal-status-options {
  margin-left: auto;
  padding-right: 18px;
  box-sizing: border-box;
  width: 100%;
  max-width: 150px;

  li {
    padding: 6px 0px;
    box-sizing: border-box;
    text-align: right;
  }
}

.cg-goalstories-currentgoal-imgsteps-details-progbar {
  width: 100%;
}

.cg-goalstories-currentgoal-noticethreads-cont {
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  overscroll-behavior: contain;

  @media screen and (min-width: $mobile-top) {
    animation: fadeIn 280ms ease-in-out;
    animation-delay: 180ms;
    animation-fill-mode: backwards;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    left: 10%;
    width: 80%;
    max-height: calc(100vh - 10px);
    background-color: rgb(48, 48, 48);
    border-radius: 9px 9px 0px 0px;
    padding-bottom: 10px;
    // background-color: rgba(6, 32, 41, 0.75);
  }
}

.cg-goalstories-currentgoal-noticethreads-icon-wrapper {
  padding: 12px 0px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  touch-action: none;
  position: relative;
}

.cg-goalstories-currentgoal-noticethreads-icon-wrapper-inner-cont {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
}

.cg-goalstories-currentgoal-noticethreads-messages-count {
  // position: absolute;
  // left: 0px;
  // top: 0px;
  font-size: 12px;
  opacity: 0.4;
}

.cg-goalstories-currentgoal-noticethreads-icon {
  margin: 0px auto;

  line {
    stroke: var(--font-color);
  }
}

.currentgoal-notice-newcomment-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-left: 16px;
    padding-right: 12px;
  }
}

.currentgoal-notice-newcomment-userthumb-cont {
  margin-top: auto;
  width: 34px;

  @media screen and (min-width: $mobile-top) {
    padding-bottom: 8px;
  }
}

.currentgoal-notice-newcomment-input-cont {
  padding-top: 20px;
  flex-grow: 1;
  position: relative;
}

.currentgoal-notice-newcomment-replying-cont {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  font-size: 11px;
  color: var(--action);
}

.currentgoal-notice-newcomment-replying-msg {
  flex-grow: 1;
  font-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 90px);
  color: inherit;
}

.currentgoal-notice-newcomment-replying-close-icon {
  fill: var(--action);
}

.currentgoal-notice-newcomment-wrapper {
  flex-grow: 1;
  font-family: var(--font-family);
  font-size: 14px;
  color: var(--white);
  font-size: 18px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 14px;
  }

  &::after {
    padding: 8px 12px;
  }
}

.currentgoal-notice-newcomment-input {
  padding: 10px 21px;
  box-sizing: border-box;
  font-family: var(--font-family);
  font-size: 18px;
  color: var(--white);
  background-color: var(--opaque-bg);
  border-radius: 24px;
  width: 100%;
  border: none;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 18px;
  }

  &::placeholder {
    font-weight: bold;
  }
}

.currentgoal-notice-newcomment-btn-cont {
  margin-top: auto;
  //   padding-bottom: 8px;
  box-sizing: border-box;
  width: 28px;

  @media screen and (min-width: $mobile-top) {
    padding-bottom: 8px;
  }
}

.currentgoal-notice-newcomment-btn {
  padding: 6px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // &.active {
  //   background-color: var(--white);

  //   svg {
  //     fill: var(--black-font);

  //     g,
  //     path {
  //       fill: var(--black-font);
  //     }
  //   }
  // }

  svg {
    fill: var(--white);

    g,
    path {
      fill: var(--white);
    }
  }
}

// * ==============================
// * DESKTOP DATA STYLES ==========
.cg-goalstories-currentgoal-desktop-data-cont {
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}

[class*="cg-goalstories-currentgoal-desktop-data-inner-cont-"] {
  width: 50%;
}
.cg-goalstories-currentgoal-desktop-data-inner-cont-left {
  .cg-goalstories-currentgoal-imgsteps-cont {
    margin-top: 0px;
    height: 50vh;
    max-height: 60vh;
    // aspect-ratio: unset;
    justify-content: center;
    align-items: center;
  }
}

.cg-goalstories-currentgoal-desktop-data-inner-cont-right {
  display: flex;
  flex-direction: column;
  gap: 44px;

  .cg-goalstories-currentgoal-imgsteps-details-cont {
    max-height: unset;
    background-color: var(--transparent);
  }

  .cg-goalstories-currentgoal-imgsteps-details-progbar {
    margin-top: 8px;
  }

  .cg-goalitems-goal-items-list {
    padding-top: 20px;
  }

  .cg-goalstories-currentgoal-desktop-data-inner-notices-cont {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

// * SELECT_MENU STYLES
.details-goal-status {
  &__option {
    border: 2px solid var(--bg-color);
    border-radius: 5px;

    &:focus-within {
      // outline: 2px solid var(--focus-outline-color);
      border: 2px solid var(--focus-outline-color);
    }
    &:focus {
      // outline: 2px solid var(--focus-outline-color);
      border: 2px solid var(--focus-outline-color);
    }
    &:hover {
      // outline: 2px solid var(--focus-outline-color);
      border: 2px solid var(--focus-outline-color);
      cursor: pointer;
    }
  }
}

// .plan-editgoal-inputs-dropdowns {
//   display: flex;
//   margin-top: 30px;
//   @media screen and (max-width: $mobile-breakpoint) {
//     margin-top: 10px;
//     display: unset;
//   }
// }

.details-goal-status {
  & &__control {
    background-color: transparent;
    color: var(--font-color);
    border: 1px solid #ffffff;
    width: 150px;

    &:focus {
      border-color: var(--focus-outline-color);
    }

    &:hover {
      border-color: var(--focus-outline-color);
      cursor: pointer;
    }

    @media screen and (min-width: $mobile-top) {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      height: 24px;
      max-height: 24px;
      min-height: unset;
      width: 115px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu {
    background-color: var(--bg-color);
    color: var(--font-color);
    height: fit-content;
    width: 160px;
    border: none;

    @media screen and (max-width: 500px) {
      margin: 0px;
      padding: 0px;
      padding-bottom: 6px;
      box-sizing: border-box;
      width: 115px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu-option,
  & &__option {
    background-color: transparent;
    color: var(--font-color);

    @media screen and (max-width: 500px) {
      height: 16px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__single-value {
    color: var(--font-color);
    overflow: visible;
    margin-top: 2px;
  }

  & &__placeholder {
    margin-top: 0px;
    color: var(--font-color);
  }

  & &__indicator-separator {
    display: none;
  }

  & &__dropdown-indicator {
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
    box-sizing: border-box;

    .list-showing & {
      transform: rotate(180deg);
    }

    @media screen and (max-width: $mobile-breakpoint) {
      // margin-top: -5px;
      padding: 0px;
    }
  }

  & &__indicator-seperator {
    width: 0px;
  }

  & &__indicators-container {
    @media screen and (max-width: $mobile-breakpoint) {
      height: var(--input-font-size, 14px);
    }
  }

  & &__value-container {
    padding: 6px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    min-height: 22px;
    box-sizing: border-box;

    @media screen and (min-width: $mobile-top) {
      margin-top: 2px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__input-container {
    margin: 0px;
    max-width: 5px;
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & &__input {
    max-width: 5px;
  }
}

.cg-goalstories-currentgoal-type-year {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18px;
  gap: 20px;

  @media screen and (max-width: $mobile-breakpoint) {
    position: absolute;
    top: -30px;
    margin-bottom: 0px;
  }
}

.cg-goalstories-currentgoal-type {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  width: 115px;
  background-color: var(--action);
  color: var(--white);
  border-radius: 20px;
  // margin-right: 20px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10px;
    line-height: 14px;
    width: 75px;
    margin-right: 8px;
  }
}

.cg-goalstories-currentgoal-year {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  width: 80px;
  background-color: var(--white);
  color: var(--action);
  border-radius: 20px;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 10.0411px;
    line-height: 14px;
    width: 50px;
  }
}

.cg-goalstories-currentgoal-image-cont {
  // max-height: 427.55px;
  // min-height: 232.17px;
  // max-width: 317.24px;
  // min-width: 195.38px;
  // width: 40%;

  width: 30%;
  // background-size: cover;

  // &:empty {
  //   background-color: var(--opaque-bg);
  // }

  @media screen and (max-width: $mobile-breakpoint) {
    // min-height: 108px;
    // min-width: 89px;
    // max-height: 108px;
    // max-width: 89px;
  }
}

.cg-goalstories-currentgoal-image {
  // max-height: 100%;
  margin-left: auto;
  max-height: 368px;
  max-width: 100%;
  border-radius: 10px;
  aspect-ratio: 270 / 371;

  &.no-image {
    background-color: var(--opaque-bg);
    position: relative;

    &:empty::after {
      content: "No Image";
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: var(--font-color);
      opacity: 0.25;
    }
  }
}

.cg-goalstories-currentgoal-image-photoswipe {
  .pswp__caption {
    opacity: 0;
  }
}

.cg-goalstories-currentgoal-image-and-details {
  display: inline-flex;
  max-width: 1000px;
  width: 100%;
  gap: 20px;
  min-height: 200px;

  @media screen and (max-width: $mobile-breakpoint) {
    min-height: unset;
    max-height: none;
    height: fit-content;
    gap: 0px;

    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: rgba(109, 109, 109, 0.12);
  }
}

.cg-goalstories-currentgoal-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 27px;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  overflow-wrap: anywhere;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 25px;
    line-height: 30px;
  }
}

.cg-goalstories-currentgoal-details {
  position: relative;
  // min-height: 232.17px;
  // max-height: 427.55px;
  width: 70%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-right: 12px;
    min-height: unset;
    width: 70%;
    gap: 6px;
  }
}

.cg-goalstories-currentgoal-details-extra-details {
  margin-top: auto;
  padding-right: 20px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  bottom: 0px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 6px;
    padding-right: 0px;
    font-size: 12px;
    flex-wrap: wrap;
  }
}

.cg-goalstories-currentgoal-details-extra-details-status {
  font-family: "Montserrat";
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;

  @media screen and (max-width: $mobile-breakpoint) {
    // font-size: 8.11743px;
    font-size: inherit;
    width: 100%;
    flex-grow: 1;
    gap: 8px;
    // line-height: 10px;
  }
}

.cg-goalstories-currentgoal-details-extra-details-label {
  width: 85px;
  font-size: 14px;
  min-height: 5px;
  display: inline-block;
}

.cg-goalstories-currentgoal-details-extra-details-dueDate,
.cg-goalstories-currentgoal-details-extra-details-lastmodified {
  font-family: "Montserrat";
  margin-top: 22px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: inherit;
    font-size: 14px;
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    // font-size: 8.11743px;
    // line-height: 10px;
    margin-top: 0px;
    // margin-top: 25px;
  }
}

.cg-goalstories-currentgoal-details-extra-details-lastmodified {
  margin-top: 12px;
}

.cg-goalstories-currentgoal-details-extra-details-vertical-bar {
  width: 1px;
  height: 38px;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media screen and (min-width: $mobile-top) {
    display: none;
  }
}

.cg-goalstories-currentgoal-details-extra-details-dueDate-picker,
.cg-goalstories-currentgoal-details-extra-details-lastmodified-picker {
  border: 1px solid var(--white);
  border-radius: 5px;
  background-color: transparent;
  color: var(--white);
  width: 122px;
  margin-left: 5px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 12px;
    line-height: 10px;
    width: 118px;
    margin-left: 0px;
  }
}

.cg-goalstories-currentgoal-details-extra-details-created {
  margin-left: auto;
  opacity: 0.4;
  font-family: "Montserrat";

  .cg-goalstories-currentgoal-details-extra-details-label {
    display: none;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    margin-left: unset;
    font-size: 12px;
    line-height: 10px;
    opacity: 0.4;

    .cg-goalstories-currentgoal-details-extra-details-label {
      display: inline-block;
    }
  }
}

.cg-goalstories-currentgoal-steps-cont {
  width: 49.5%;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 100%;

    // border-radius: 16px;
    // padding: 12px;
    // box-sizing: border-box;
    // /* background-color: var(--opaque-bg); */
    // background-color: #292929;
    // margin-right: 12px;
  }
}

.cg-goalstories-currentgoal-steps-chat {
  display: inline-flex;
  width: 100%;

  padding: 12px;
}

.cg-goalstories-currentgoal-steps-title {
  font-size: 27px;
  line-height: 40px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 20px;
    line-height: 26px;
  }
}

.cg-goalstories-currentgoal-legacy-steps {
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--opaque-bg);
  width: 100%;
}

.cg-goalstories-currentgoal-legacy-steps-title {
  font-size: 18px;
}

.cg-goalstories-currentgoal-legacy-steps-msg {
  font-size: 13px;
  padding: 12px 0px;
  box-sizing: border-box;
  color: var(--warning);
}

.cg-goalstories-currentgoal-legacy-steps-renderbox {
  width: 100%;
}

.cg-goalstories-currentgoal-no-steps-msg {
  padding: 20px 0px;
}

.cg-goalstories-currentgoal-steps {
  display: grid;
}

.cg-goalstories-currentgoal-step {
  display: inline-flex;
  margin-top: 25px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 14px;
  }
}

.cg-goalstories-currentgoal-step-desc {
  line-height: 22px;
  font-size: 18px;
  font-family: "Montserrat";
  font-style: normal;
  align-self: center;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 14px;
    line-height: 12px;
  }
}

.cg-goalstories-currentgoal-noduration-msg-cont {
  padding: 6px 0px 2px;
  box-sizing: border-box;
  width: 100%;
}

.cg-goalstories-currentgoal-noduration-msg {
  padding: 4px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
  color: var(--warning);
  background-color: var(--warning-semi-transparent);
  display: inline-block;
}

.cg-goalstories-currentgoal-goalWeight-percentComplete {
  margin-bottom: 10px;
  width: 78px;
  font-family: "Montserrat";
  margin-right: 20px;

  @media screen and (max-width: $mobile-breakpoint) {
    width: 40px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
}

.cg-goalstories-currentgoal-step-checkbox {
  margin-right: 12px;
  margin-top: 1px;
}
.cg-goalstories-currentgoal-goalWeight-cont {
  display: inline-flex;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--opaque-bg);
  border-top: 1px solid var(--opaque-bg);
  gap: 2px;
  align-items: center;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-top: 10px;
    padding-bottom: 13px;
    // padding-top: 5px;
    // padding-bottom: 10px;

    margin-top: 5px;
    margin-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    border-top: none;
    border-bottom: none;
  }
}

.cg-goalstories-currentgoal-goalWeight-percentComplete-h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 27px;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;

  &.is-complete {
    animation: successPulse 1450ms ease-in-out 1 300ms;
    animation-fill-mode: backwards;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 18px;
    line-height: 24px;
    width: 40px;
  }
}

@keyframes successPulse {
  0% {
    transform: scale(1);
  }
  12% {
    transform: scale(1.06);
  }
  24% {
    transform: scale(1);
    color: var(--success, #409432);
  }
  36% {
    transform: scale(1.06);
  }
  48% {
    transform: scale(1);
    color: var(--success, #409432);
  }
  60% {
    transform: scale(1.06);
  }
  100% {
    transform: scale(1);
  }
}

.cg-goalstories-currentgoal-goalWeight-percentComplete-p {
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 6px;
    line-height: 9px;
  }
}

.cg-goalstories-currentgoal-goalWeight-step {
  height: 22px;
  background-color: var(--action);
  border-radius: 10px;
  min-width: 25px;
  border: 2px solid var(--action);
  box-sizing: border-box;
  // filter: brightness(50%);

  // &.goal-completed {
  //   filter: unset;
  // }

  @media screen and (max-width: $mobile-breakpoint) {
    height: 9px;
    min-width: 9px;
  }
}

.cg-editgoal-goal-goalweight-step-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-sizing: border-box;
  backdrop-filter: brightness(0.4);
  transition: backdrop-filter 180ms ease-in-out;
  position: relative;

  .cg-goalstories-currentgoal-goalWeight-step.goal-completed & {
    backdrop-filter: none;
  }

  &:hover {
    @media screen and (min-width: $mobile-top) {
      cursor: help;

      &::after {
        content: attr(data-itemweight);
        font-size: 18px;
        color: inherit;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: -0px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fadeTextIn 180ms ease-in-out;
      }

      &.less-than-5::after {
        top: -24px;
        animation: slideTextUpFromBottom 180ms ease-in-out;
      }
    }
  }
}

@keyframes slideTextUpFromBottom {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.cg-goalstories-currentgoal-chat-cont {
  transition: max-height 280ms ease-in-out;
  overflow: hidden;
  animation: chatFadeIn 180ms linear 300ms;
  animation-fill-mode: backwards;

  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 500px;
  max-width: 85%;
}

@keyframes chatFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cg-goalstories-currentgoal-chat-cont-textedit-modaled {
  // --_chat-display: inline-flex;

  // position: absolute;
  // bottom: 0px;
  // right: 20px;
  display: var(--_chat-display, inline-flex);
  height: 60px;
  max-height: 60px;
  gap: 100px;
  // width: 47%;
  justify-content: center;
  align-items: center;
  border-radius: 18px 18px 0px 0px;
  align-items: center;
  background-color: #1c1c1c;
  box-shadow: 1px 1px 4px rgba(255, 255, 255, 0.28);

  @media screen and (max-width: $mobile-breakpoint) {
    padding-left: 20px;
    padding-right: 20px;
    width: 80%;
    bottom: 0px;
    position: fixed;
    left: 10%;
  }
}

.cg-goalstories-currentgoal-chat-cont-unopened {
  @media screen and (max-width: $mobile-breakpoint) {
    padding: 0px;
    left: unset;
    right: 12px;
    bottom: 10px;
    height: auto;
    max-height: unset;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // box-shadow: 0px 0px 4px rgba(255, 255, 255, 28%);
    box-shadow: none;
  }
}

.chat-textedit-modaled-msg-icon {
  position: relative;

  // & > svg {
  //   filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 28%));
  // }

  // &::before {
  //   content: "Messages";
  //   position: absolute;
  //   font-size: 12px;
  //   color: var(--font-color);
  //   left: 0px;
  //   bottom: -20px;
  //   align-items: center;
  //   font-family: "Oswald";
  //   font-weight: 400;
  // }

  &::after {
    content: attr(data-msgcount);
    font-size: 18px;
    color: var(--alert);
    position: absolute;
    width: 100%;
    height: 90%;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Oswald";
    font-weight: 400;
  }

  &.no-comments {
    &::after {
      color: var(--black-font);
    }
  }
}

.cg-goalstories-currentgoal-chat-cont-textedit-modaled-button {
  // margin-left: 60px;
  // margin-right: -40px;
  // position: absolute;
  // right: 20px;
  // bottom: 27px;
}

.cg-goalstories-currentgoal-chat-cont-opened {
  // position: absolute;
  position: fixed;
  // bottom: 0px;
  // right: 20px;
  // width: 550px;
  // max-width: 85%;
  // max-height: 80px;
  background-color: rgb(48, 48, 48);
  padding: 20px;
  border-radius: 18px 18px 0px 0px;

  @media screen and (max-width: $mobile-breakpoint) {
    background-color: transparent;
  }
}

.currentgoal-notices-selection-options {
  display: flex;
  margin: auto;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  gap: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  @media screen and (max-width: 930px) {
    text-align: center;
    font-size: 11.5px;
    line-height: 17px;
    margin-top: 19px;
  }

  .currently-selected-section {
    border-bottom: 1px solid var(--white);
  }
}

.cg-goalstories-currentgoal-chat-cont-textedit-modaled-text {
  // margin-left: 5px;
  // margin-right: 40px;
}
