@import "../../../styles/variables";

.login-existing-sessions-cont {
  width: 100%;
  max-width: 350px;
}

.login-existing-sessions-title {
  margin: 0px;
  padding: 6px 0px;
  font-size: 13px;
}

.login-existing-sessions-list {
  padding: 10px 0px 30px;
  list-style: none;
}

.login-existing-sessions-list-item {
  &:nth-of-type(n + 2) {
    margin-top: 8px;
  }
}

.login-existing-sessions-list-item-button {
  padding: 6px;
  border: 1px solid $grey-dark;
  background-color: $grey-dark;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;

  &:nth-of-type(n + 2) {
    margin-top: 8px;
  }
}

.login-existing-sessions-list-item-userthumb {
  //   background-color: $action;
  background: $blue-gradient;
  background-color: $action;
}

.login-existing-sessions-list-item-username {
  margin-right: auto;
  margin-left: 16px;
}

.login-existing-sessions-list-item-domainid {
  color: $grey-light;
}

.login-existing-sessions-showothers {
}
