@import "../../../../styles/variables";

.cc-asset-video-poster-image-wrapper {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $black;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 800px) {
    border-radius: 0px;
  }
}

.cc-asset-video-poster-image {
  max-width: 100%;
  object-fit: contain;
}

.cc-asset-video-play-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background: radial-gradient(rgba(0, 0, 0, 0.75), transparent);

  &.is-replay {
    background-color: rgba(0, 0, 0, 0.4);
  }

  button[name="play-circle"] {
    &:hover {
      .cc-asset-video-play-btn {
        transition: fill 180ms ease-in-out;
        fill: var(--action);
      }
    }
  }
}

.cc-asset-video-play-btn {
  fill: var(--white-font);

  .is-replay & {
    fill: $success;
    // filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
  }
}

.cc-asset-video-play-btn-text {
  margin: 0px;
  padding: 6px 0px 0px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 16px;
  // color: $white-font;
  color: $success;
  // text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
  font-weight: bold;

  //   opacity: 0.4;
}
