@import "../../../../styles/variables";

nav [class*="cm-subnavs-goalnav-cont-"] {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cm-subnavs-goalnav-cont-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.cm-subnavs-goalnav-person-userthumb {
  margin: 0px 10px;

  @media screen and (min-width: $mobile-top) {
    margin: 0px;
  }
}

.cm-subnavs-goalnav-person-name {
  font-size: 13px;
}

.cm-subnavs-goalnav-goal-time {
  padding: 0px 6px;
  box-sizing: border-box;
  font-size: 11px;
}

.cm-subnavs-goalnav-cont-right {
}
