@import "../../../styles/variables";

.cc-menubtn {
  padding: 4px 6px;
  // background-color: var(--grey-light-bg);
  background-color: transparent;
  color: var(--black-font);
  font-size: 14px;
  border: none;
  // outline: none;

  &.no-bg-color {
    background-color: var(--transparent);
  }
}

[data-reach-popover] {
  z-index: 15;
}

[data-reach-menu] {
  z-index: 15;

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 12px 0px 20px;
    box-sizing: border-box;
    position: fixed !important;
    left: 0px !important;
    bottom: 0px !important;
    top: unset !important;
    width: 100vw;
    background-color: var(--opaque-bg-inverse);
    // background-color: $black-font;
    min-height: 25vh;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 8px 8px 0px 0px;
    animation: slideFromBottom 480ms ease-in-out;
    background-color: var(--font-color);
    color: var(--bg-color);

    // .App.light-mode {
    // .App[data-theme="light"] {
    //   background-color: $white-font;
    // }
  }
}

.cc-menubtn-list {
  padding: 0px;
  border-radius: 6px;
  overflow: hidden;
  background-color: var(--context-menu-font-color);
  // border: 1px solid var(--font-color);
  z-index: 15;
  background-color: var(--context-menu-bg);
  border: 1px solid var(--context-menu-border-color);

  &.animate-reveal {
    animation: subtleSlideDown 280ms ease-in-out;

    @media screen and (max-width: $mobile-breakpoint) {
      animation-name: subtleSlideUp;
    }
  }

  @media screen and (min-width: $mobile-top) {
    background-color: var(--context-menu-bg);
    border: 1px solid var(--context-menu-border-color);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    padding: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
  }
}

.cc-menubtn-cancel-list-item {
  display: none;
}

.cc-menubtn-list-item {
  --_divider-border-color: #252525;
  --_menu-item-border-top: 12px;
  --_menu-item-border-side: 16px;

  padding: 0px;
  font-family: var(--font-family);

  & > a {
    color: inherit;
    text-decoration: inherit;
    font-family: var(--font-family);
  }

  &:nth-of-type(n + 2) {
    border-top: 1px solid var(--_divider-border-color);
    // border-top: 1px solid var(--grey-border);
  }

  // @media screen and (min-width: 801px) {
  // &:first-of-type {
  // 	border-radius: 8px 8px 0px 0px;
  // }

  // &:nth-last-of-type(2) {
  // 	border-radius: 0px 0px 8px 8px;
  // }
  // }

  button,
  a,
  & > span {
    padding: var(--_menu-item-border-top) var(--_menu-item-border-side);
    width: 100%;
    text-align: inherit;
    display: block;
  }

  @media screen and (min-width: $mobile-top) {
    // padding: 12px 16px;
    font-size: 16px;
    color: var(--white);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    --_menu-item-border-top: 18px;
    --_menu-item-border-side: 18px;

    border-color: var(--opaque-bg-inverse);
    padding: 0px;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    font-size: 18px;
    border-radius: 0px;
    background-color: rgba(63, 63, 63, 0.8);
    backdrop-filter: blur(50px);
    color: var(--white);

    &:first-of-type {
      border-radius: 10px 10px 0px 0px;
    }

    &:nth-last-of-type(2) {
      border-radius: 0px 0px 10px 10px;
    }

    &.cc-menubtn-cancel-list-item {
      margin-top: 10px;
      display: block;
      border-radius: 10px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 18px;

    &:hover {
      background-color: transparent;
    }
  }
}
