@import "../../../../styles/variables";

.plan-goals {
  // height: 100%;
  @media screen and (max-width: $mobile-breakpoint) {
    height: 100%;
  }
}

.plan-goals-loading-center {
  text-align: center;
  margin: auto;
  font-size: 1.2em;
}
