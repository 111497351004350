@import "../../../styles/variables";

.modals-agnostic-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  fill: $black;
  z-index: 100;

  // &.is-supermodal {
  //   right: unset;
  //   left: 20px;
  // }
}

.modals-agnostic-title-text {
  margin: 0px auto;
  padding: 0px;
}
