@import "../../styles/variables";

.interface-404-notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notfound-title {
  margin-top: -100px;
  margin-bottom: 50px;
  max-width: 400px;
}

.notfound-text {
  max-width: 350px;
  line-height: 18px;
}

.notfound-current-path {
  padding: 3px 5px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: $opaque-background;
  font-family: serif;
}

.notfound-text-link {
  color: $action;
}
