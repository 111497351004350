.smartGoals-modal-dialog-container {
  background-color: var(--bg-color);
  color: var(--white);
  font-family: var(--font-family);
}

.smatGoals-modal-letter-container {
  margin-top: 39px;
}

.smartGoals-modal-letter-section {
  margin-bottom: 20px;
}

.smartGoals-modal-letter-head {
  margin-bottom: 3px;
}
