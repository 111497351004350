@import "../../../styles/variables";

.cc-input {
  padding: 6px;
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid;
  border-color: var(--border-color);
}

.cc-input {
  &.textarea {
    resize: none;
  }
}

// * NOTE: This approach was ripped from CSS Tricks
// * To allow for auto-sizing textarea:
// * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
.cc-input-textarea-wrapper {
  --_textarea-border-width: 0px;

  margin: 0px;
  padding: 0px;
  display: grid;
  position: relative;
  word-break: break-all;

  &::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-replicated-value) " ";

    word-break: break-all;
    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }

  .cc-input-textarea-fake-content-cont {
    // opacity: 0;
    visibility: hidden;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  .cc-input-textarea-fake-content {
    white-space: nowrap;
    position: absolute;
  }

  & > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }

  & > .cc-input-textarea-fake-content-cont,
  & > textarea,
  &::after {
    /* Identical styling required!! */
    border: var(--_textarea-border-width) solid black;
    // padding: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    // font: inherit;

    /* Place on top of each other */
    grid-area: 1 / 1 / 2 / 2;
  }
}
