@import "../../styles/variables";

.interface-ppfplan {
  padding: 30px 30px;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.ppfplan-main-nav-grib-btn {
  margin-left: auto;
}

.ppfplan-main-nav {
  padding: 13px 0px 30px;
}

.ppfplan-main-nav-mobile {
  margin-top: 15px;
  margin-bottom: 10px;
}

.plan-parentgoal-title {
  padding-bottom: 30px;
}

.plan-parentgoal-menu-btn {
  margin-right: 12px;
}

.ppfplan-extra-nav-for-stories-icon-left {
  position: absolute;
  top: 400px;
  left: -15px;
  @media screen and (max-width: $mobile-breakpoint) {
    top: 35px;
    left: 10px;
  }
}

.ppfplan-extra-nav-for-stories-icon-right {
  position: absolute;
  top: 400px;
  right: -10px;
  @media screen and (max-width: $mobile-breakpoint) {
    top: 35px;
    right: 10px;
  }
}

main.interface-ppfplan-mobile-adjustments {
  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: unset;
    // padding-bottom: unset;
    height: auto;
  }
}

.interface-ppfplan-grid-status-filter {
  margin-left: auto;
}
