@import "../../styles/variables";
@import "../../styles/animations";

.modals-dialog-overlay {
  padding: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    var(--bg-gradient);
  // background: linear-gradient(to top, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
  //   // url("/background-pattern-1.png"),
  //   linear-gradient(to top, rgba(34, 57, 88, 0.85), rgba(0, 0, 0, 0.85));
  background-size: 100%;
  animation: fadeIn 400ms ease-in-out;
  z-index: 1000;
  font-family: "Open Sans", sans-serif;

  &.disallow-close {
    // ? Does this break Modal nested inside? Very likely, yes...
    pointer-events: none;
  }

  &.is-supermodal {
    padding: 0px 0px 0px 20px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media screen and (max-width: 800px) {
    padding: 0px;
  }
}

.modals-dialog-content {
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: var(--bg-color);
  width: 525px;
  height: 500px;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 100%;
  }

  // animation: slideInFromBottom 800ms ease-in-out;

  // &.is-supermodal {
  //   animation-name: slideInFromRight;
  //   height: 100vh;
  //   max-height: 100vh;
  //   width: calc(100vw - 40px);
  //   max-width: 1000px;
  // }
}
