.cc-icon-ballon-main {
    fill: var(--success);
}

.cc-icon-ballon-border{
    fill: var(--black);
}

.cc-icon-balloon-overlay {
    fill: var(--black);
    opacity: 0.24;
}