@import "../../../styles/variables";

.modals-textedit-modal-overlay {
  background: unset;
  background-color: rgba(0, 0, 0, 0.75);
}

.modals-textedit-modal-main-wrapper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0px;

  @media screen and (max-width: 800px) {
    .modals-agnostic-close-button {
      padding: 2px 6px 2px 8px;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 0px 0px 0px 5px;
    }
  }
}

.textedit-modal-main-cont {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 35px;
  padding-bottom: 35px;

  @media screen and (max-width: 800px) {
  }
}

.textedit-modal-article-cont {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.document-viewer-image-cont {
  max-height: 90vh;
  max-width: 95vw;
  margin-top: 12px;
  margin-bottom: 10px;
  background-color: transparent;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}

.document-viewer-image-label {
  font-size: 12px;
  color: $white-font;
  opacity: 0.6;
  padding-bottom: 4px;
}

.document-viewer-image {
  max-height: 90vh;
  max-width: 95vw;
  touch-action: pan-down pan-up pinch-zoom;

  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
}

.textedit-modal-inner-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textedit-modal-buttons-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.textedit-modal-done-button {
  font-size: 14px;
}

.textedit-modal-text-wrapper {
  width: 100%;
  flex-grow: 1;
}

.textedit-modal-textfield-cont {
  border: 1px solid var(--opaque-bg);
  border-radius: 5px;
}

.textedit-modal-back-button {
  padding: 4px;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: var(--font-color);
  border: none;
  font-family: inherit;
}

.textedit-modal-back-button-text {
  font-size: inherit;
  color: inherit;
  font-size: 16px;
}

.textedit-modal-text-label {
  padding: 0px 0px 12px;
  box-sizing: border-box;
  font-size: 18px;
  color: var(--font-color);
}
