@import "../../styles/variables";

.interface-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-logo {
  margin-top: -100px;
  width: calc(100% - 40px);
  margin-bottom: 30px;
  max-width: 345px;
  // max-width: 375px;

  @media screen and (max-width: 800px) {
    padding: 0px 12px;
    box-sizing: border-box;
    max-width: 300px;
  }
}

.login-inner-cont {
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-input-label {
  padding-bottom: 6px;
  width: 100%;

  &:nth-of-type(n + 2) {
    margin-top: 12px;
  }
}

.login-input {
  padding: 8px;
  width: 100%;
  border-radius: 5px;
}

.login-error-msg {
  color: $alert;
  margin-top: 20px;
}

.login-button {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 40px;
  width: 100%;

  .login-error-msg ~ & {
    margin-top: 15px;
  }
}
