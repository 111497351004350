//* Styles are Mobile be default.
@import "../../../../../styles/variables";

//Temporary Override
.cm-menu {
  // top: 0px !important;
  // height: 100% !important;
}

.cm-menu-search-result-link {
  text-decoration: none;
}

.cm-menu-main-search-wrapper {
}

.cm-menu-top-cont-search {
  padding: 60px 21px 0px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $mobile-top) {
    padding-right: 0px;
  }
}

.cm-menu-top-search-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 21px;

  @media screen and (min-width: $mobile-top) {
    gap: 12px;
  }
}

.cm-menu-search-input-wrapper {
  flex-grow: 1;

  .creating-plan & {
    opacity: 0.75;
    pointer-events: none;
  }
}

.cm-menu-search-lastchanged-date {
  font-size: 12px;
  line-height: 12px;
}

.cm-menu-search-input {
  // box-sizing: border-box;
  // border-radius: 8px;
  // border-color: transparent;
  // flex-direction: row;
  // align-items: center;
  // background-color: #29303c;
  // display: inline-block;
  // height: 51px;
  // width: 296px;
  // left: 0px;
  // top: 0px;
  // padding: 12px;
  // padding-left: 40px;
  font-size: 15px;
}

.cm-menu-search-input:focus {
  // outline: none !important;
  // border: 1px solid #0290ff;
  // box-shadow: 0 0 3px #719ece;
}

.cm-menu-search-cancel {
  // display: inline-block;
  font-size: 15px;
  font-weight: 600;
  // line-height: 24px;
  // text-align: right;
  // position: relative;
  // left: 310px;
  // top: -40px;
  color: var(--action-alt);
}

.cm-menu-search-details {
  max-width: 414px;
  height: 100%;
  padding: 25px 10px 10px 20px;
}

.cm-menu-search-card {
  // height: 75px;
  padding: 15px 0px;
  max-width: 375px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;

  &:nth-of-type(n + 2) {
    border-top: var(--opaque-bg) 1px solid;
  }

  .creating-plan & {
    opacity: 0.4;
    pointer-events: none;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    flex-wrap: nowrap;
  }
}

.cm-menu-search-thumbnail {
}

.cm-menu-search-thumbnail {
  // display: inline-block;
  // margin-right: 10px;
  box-sizing: border-box;
  // padding-right: 10px;
}

.cm-menu-search-card-right {
  // width: calc(100% - 56px);
  flex-grow: 1;
  // width: calc(100% - 50px);
}

.cm-menu-search-card-right-inner-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cm-menu-search-link-user {
  padding: 0px;
  text-decoration: none;
  font-size: 15px;
  font-family: var(--font-family);
  width: 205px;
  text-align: left;
  overflow: hidden;
}

.cm-menu-search-link-create {
  padding: 0px;
  text-decoration: none;
  font-size: 14px;
  font-family: var(--font-family);
  width: 105px;
  flex-basis: 105px;
  margin-left: auto;
  text-align: right;
}

//Desktop - accouts for narrow nav bar
@media screen and (min-width: 801px) {
  .cm-menu-search-link-user {
    width: 145px;
    overflow: hidden;
  }
  .cm-menu-search-link-create {
    width: 90px;
    font-size: 13px;
  }
}

.cc-link.is-button.cm-menu-search-result-link {
  margin-left: -9px;
}

.cm-menu-search-privileges {
  margin-top: 3px;
  text-decoration: none;
  font-size: 10px;
  font-family: var(--font-family);
  // margin-right: -137px;
  // margin-top: 30px;
  // color: #6d7178;
  color: var(--grey);
  // float: left;
  display: flex;
  flex-direction: row;
}

// .cm-menu-search-instruction {
//   margin-top: 100px;
//   text-align: center;
//   color: #272d37;
//   font-size: 20px;
//   font-family: var(--font-family);
//   margin-left: -10px;
// }

//* Desktop Overrides
@media screen and (min-width: 801px) {
  // .cm-menu-search-input {
  //   width: 90%;
  // }
  // .cm-menu-search-cancel {
  //   display: inline-block;
  //   font-size: 11px;
  //   font-weight: 600;
  //   line-height: 24px;
  //   text-align: right;
  //   position: relative;
  //   left: 202px;
  //   top: -40px;
  //   color: #0f77f0;
  // }
  // .cm-menu-search-details {
  //   max-width: 414px;
  //   height: 100%;
  //   padding: 0 10px 10px 20px;
  // }

  // .cm-menu-search-card {
  //   height: 75px;
  //   max-width: 317px;
  //   border-bottom: #272d37 1px solid;
  //   display: flex;
  //   justify-content: left;
  //   align-items: center;
  //   // background-color: tan;
  // }

  // .cm-menu-search-thumbnail {
  //   display: inline-block;
  //   margin-right: 10px;
  // }

  // .cm-menu-search-link-user {
  //   text-decoration: none;
  //   font-size: 13px;
  //   font-family: var(--font-family);
  //   white-space: nowrap;
  //   overflow: hidden;
  //   max-width: 155px;
  // }

  // .cm-menu-search-link-create {
  //   text-decoration: none;
  //   font-size: 11px;
  //   font-family: var(--font-family);
  //   position: absolute;
  //   left: 80%;
  // }

  // .cm-menu-search-privileges {
  //   text-decoration: none;
  //   font-size: 9px;
  //   font-family: var(--font-family);
  //   margin-right: -124px;
  //   margin-top: 30px;
  //   color: #6d7178;
  //   float: left;
  // }

  // .cm-menu-search-instruction {
  //   margin-top: 100px;
  //   text-align: center;
  //   color: #272d37;
  //   font-size: 20px;
  //   font-family: var(--font-family);
  //   margin-left: -10px;
  // }
}

.cm-menu-search-result-error-msg {
  padding: 0px;
  font-weight: bold;
  font-size: 13px;
  color: var(--alert);
}

//* Big Desktop
@media screen and (min-width: 1350px) {
  // .cm-menu-search-link-user {
  //   max-width: 195px;
  // }

  // .cm-menu-search-cancel {
  //   left: 252px;
  // }
}
