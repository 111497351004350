@import "../../styles/variables";

.interface-help {
  padding-bottom: 40px;
  box-sizing: border-box;
  z-index: 6;

  @media screen and (max-width: 800px) {
    max-width: 100vw;
  }
}

.library-inner-cont {
  margin: 0px auto;
  box-sizing: border-box;
  width: 100%;
  display: "flex";
  width: "100%";

  @media screen and (max-width: 800px) {
    padding: 12px 0px;
    width: 100vw;
  }
}

.library-inner-items {
  margin-top: 30px;
  flex-direction: column;
}

.library-title {
  margin-bottom: 52px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #fff;
}

.ppfhelp-main-nav {
  padding: 13px 0px 30px;
}

.library-toggle-cont {
  width: 220px;
  background-color: rgba(69, 68, 68, 1);
  margin: auto;
  margin-bottom: 70px;

  border-radius: 100px;

  .library-btn {
    font-family: "Oswald", sans-serif;
    font-size: 17px;
    border-radius: 100px;
    width: 102px;
    height: 38px;
    margin: 4px;
    color: #fff;
    //background-color: rgba(203, 16, 46, 1); //CV Red
    .cc-btn-hover-bg {
      &:hover {
        border-radius: 100px;
      }
    }
  }
}

.library-video-wrapper {
  .library-title-text {
    font-size: 33px;
    font-weight: bolder;
    color: #fff;
    font-family: "Oswald", sans-serif;
    @media screen and (max-width: 800px) {
      font-size: 23px;
    }
  }

  .libary-type-text {
    font-family: "Oswald-Extralight", sans-serif;
  }

  .library-description-text {
    font-size: 14px;
    color: #777;
    font-size: 18px;
    height: 30px;
    padding: 10px 5px;
  }

  .library-video {
    background-color: #323232;
    width: 84%;
    margin: 40px 0px 10px 0px;
    border-radius: 10px;
  }

  .library-spacer {
    margin: 20px 0 30px 0;
    height: 2px;
    width: 94%;
    background-color: #323232;
  }
}

.library-doc-wrapper {
  .library-preview-images-cont {
    width: 85%;
    position: relative;

    @media screen and (max-width: 480px) {
      width: 260px;
      height: 335px;
      margin: 20px auto;
    }
    @media screen and (min-width: 481px) {
      width: 350px;
      height: 450px;
      margin: 20px auto;
      margin-left: 1%;
    }

    .library-pdf-single_1 {
      filter: drop-shadow(1px 2px 3px #0b0b0b);
      z-index: 15;
      max-height: 350px;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
    .library-pdf-single_2 {
      filter: drop-shadow(1px 2px 3px #0b0b0b);
      z-index: 10;
      height: 350px;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .library-pdf-single_3 {
      filter: drop-shadow(1px 2px 3px #0b0b0b);
      z-index: 5;
      max-height: 350px;
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
  .library-preview-images-cont-placeholder {
    width: 85%;
    position: relative;

    @media screen and (max-width: 480px) {
      width: 260px;
      margin: 20px auto;
    }
    @media screen and (min-width: 481px) {
      width: 350px;
      margin: 20px auto;
      margin-left: 1%;
    }
    .library-preview-images-cont-placeholder-text {
      margin-top: 20px;
    }
  }
}

.library-missing-text {
  margin: 20px;
}

.library-doc-close {
  position: absolute;
  right: 20%;
}

.library-doc-download {
  background-color: transparent;
  border: 1px solid #fff !important;
  color: #fff;
  height: 35px;
  width: 135px;
  border-radius: 5px;
  margin: auto;
  margin-left: 1%;
  display: flex;
  align-content: space-around;

  .library-doc-download-text {
    font-size: 16px;
    font-family: "Work-Sans", sans-serif;
    margin: auto;
  }
  .library-doc-download-icon {
    margin: auto;
  }
}

.library-no-training-message {
  text-align: center;
}
