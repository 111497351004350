@import "../../styles/variables";

.interface-login-with-sessionKey-inner-cont {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-with-sessionkey-error-msg {
  color: $alert;
}

.login-with-sessionkey-login-link {
  margin-top: 20px;
  color: $action;
}
