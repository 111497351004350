.modals-video-modal-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}

.modals-video-main-wrapper {
  background-color: transparent;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modals-video-video {
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}
