@import "../../../../styles/variables";

.cm-subnavs-generic-nav {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;
}

.cm-subnavs-generic-nav-btn-cont {
	flex-basis: 25px;

	@media screen and (max-width: $mobile-breakpoint) {
		flex-basis: 20px;
	}

	@media screen and (min-width: $mobile-top) {
		display: none;
	}
}

.cm-subnavs-generic-nav-cont {
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 21px;

	@media screen and (max-width: $mobile-breakpoint) {
		justify-content: space-between;
		gap: 0px;
	}
}
