@import "../../../styles/variables";

.cc-icon {
  fill: var(--font-color);
  // fill: $white;

  &.cc-icon-has-drop-shadow {
    stroke: var(--font-color);
    // stroke: $white;
    stroke-width: 2px;
    filter: drop-shadow(1px 2px 1px $grey-dark);
  }

  // .App.light-mode & {
  //   fill: $black-font;
  // }
}

.cc-icon-button {
  background-color: transparent;
  border: none;
  padding: 0px;

  &:hover {
    cursor: pointer;
  }
}
