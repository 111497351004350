.profile-certifications-tab-title-cont {
    margin: 0px;
    padding: 30px 0px 50px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profile-certifications-tab-title {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-size: 35px;
    color: var(--font-color);
    width: 100%;
    max-width: 1350px;
    font-weight: bold;
}

.profile-certifications-tab-loading-msg {
    .cc-message {
        font-size: 24px;
        text-align: center;
    }
}
