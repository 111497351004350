@import "../../styles/variables";

.intro-nav {
  align-items: start;
}

.interface-intro-title {
  display: flex;
  margin-top: 3px;
}

.intro-nav {
  margin-bottom: 3px;

  @media screen and (min-width: $mobile-top) {
    .cm-subnavs-generic-nav-btn-cont {
      display: initial;
    }
  }
}

.introduction-main-nav {
  padding: 43px 0px 0px;
}

.introduction-nav {
  width: fit-content;
}

.introduction-mobile-nav {
  margin-top: 30px;
  display: flex;
}

.interface-intro-title {
  margin: auto;
  width: fit-content;
  color: var(--white);
}

.intro-head-text {
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;

  margin-top: 10px;
}

.intro-video-filler {
  min-height: 206px;
  min-width: 368px;
  background-color: black;
  background-image: radial-gradient(
    circle at bottom right,
    var(--action) -50%,
    #000
  );
  text-align: center;
  aspect-ratio: 368 / 206;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 25px 0 40px 0;

  .intro-video-filler-title {
    font-size: 36px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 26px;
    }
  }
}

.intro-button-error {
  margin-top: 24px;
  display: flex;
}

.intro-button-error-redline {
  width: 2px;
  height: 36px;
  background-color: var(--alert-prog-bar);
  margin-right: 13px;
}

.intro-button-error-text {
  font-size: 18px;
}

.intro-buttons {
  margin-top: 30px;
}

.intro-buttons-loading {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}

.intro-buttons-top {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: var(--font-family);
  margin-bottom: 24px;
  border-radius: 10px;
  font-weight: 700;
}

.intro-buttons-bot {
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-family: var(--font-family);
  border-radius: 10px;
  font-weight: 700;
}

.introduction-video {
  min-width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
