.cg-parenttype-badge {
  padding: 2px 5px;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 11px;
  color: var(--white);
  background-color: var(--opaque-bg);
  text-transform: capitalize;

  &.personal {
    background-color: var(--accent-ppf-personal);
  }

  &.professional {
    background-color: var(--accent-ppf-professional);
  }

  &.financial {
    background-color: var(--accent-ppf-financial);
  }
}
