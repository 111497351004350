.cc-file-input-cont {
  position: relative;
  border: 2px dashed var(--font-color);
  border-radius: 5px;

  &.cc-file-input-cont-areacceptablefiles {
    border-color: var(--success);
  }

  &.cc-file-input-cont-arenotacceptablefiles {
    border-color: var(--alert);
  }
}

.cc-file-input {
  margin: 0px;
  padding: 8px 12px;
  box-sizing: border-box;
  color: var(--font-color);
  background-color: var(--grey-light-bg);
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.01;

  &:hover {
    cursor: pointer;
  }
}

.cc-file-input-fake-cont {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cc-file-input-fake-label {
  padding: 4px 0px;
  box-sizing: border-box;
  font-size: 10px;
  color: var(--grey-light-bg);
}

.cc-file-input-fake-button {
  // width: 100px;
  width: 140px;
}

.cc-file-input-fake-filetypes {
  padding: 4px 0px;
  box-sizing: border-box;
  font-size: 10px;
  color: var(--grey-light-bg);
}
