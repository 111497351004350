@import "../../../../../styles/variables";

.goal-nav-section-cont {
  width: fit-content;
  margin: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 0px;
    gap: 8px;
  }
}

.goal-nav-type-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  gap: 40px;
  cursor: pointer;

  &.goal-lengths {
    gap: 30px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 11.5px;
    line-height: 17px;
    gap: 18px;

    &.goal-lengths {
      gap: 12px;
    }
  }
}

.goal-nav-type-links {
  &.no-goals-of-type {
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.goal-nav-type-link {
  .goal-nav-type-links.parent-goals & {
    font-size: 18px;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
    }
  }
}

.goal-nav-year-type-link {
  padding: 3px 8px;
  border-radius: 12px;

  .goal-nav-type-links.goal-lengths & {
    font-size: 13px;
    font-weight: normal;

    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 12px;
    }
  }

  &:disabled {
    opacity: 0.25;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.goal-nav-type-link-actual {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: inherit;

  &:disabled {
    opacity: 0.25;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.selected-goal-type {
  .goal-nav-type-links.parent-goals & {
    text-decoration: underline;
    font-weight: bold;
    // border-bottom: 1px solid var(--white);
  }

  .goal-nav-type-links.goal-lengths & {
    font-weight: bold;
  }
}
