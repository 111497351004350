@import "../../../styles/variables";

.cc-message-cont {
  box-sizing: border-box;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &.fill-background {
    &.alert {
      background-color: $background-alert-transparent;
    }

    &.warning {
      background-color: $background-warning-transparent;
    }

    &.success {
      background-color: $background-success-transparent;
    }
  }
}

.cc-message {
  font-size: 16px;
  // text-align: center;
  width: 100%;
  // opacity: 0.6;

  .alert > & {
    color: $alert;
  }

  .success > & {
    color: $success;
  }

  .warning > & {
    color: $warning;
  }
}
