@import "../../../styles/variables";
@import "../../../styles/animations";

.cm-menu {
  --nav-height: 0px;

  grid-area: menu;
  animation: slideFromLeft 480ms ease-in-out;
  position: sticky;
  top: 0px;
  top: var(--nav-height);
  align-self: start;
  // overflow-y: hidden;
  height: calc(100vh - var(--nav-height));
  max-height: calc(100vh - var(--nav-height));
  background: var(--menu-bg);

  // .App[data-hasparent="true"] & {
  //   top: 0px;
  //   height: 100vh;
  //   max-height: 100vh;
  //   grid-area: 1 / 1 / 3 / 2;
  //   border-right: 1px solid var(--font-color);
  // }

  @media screen and (max-width: 800px) {
    grid-area: 1 / 1 / 3 / 2;
    // top: 0px;
    z-index: 7;
    position: fixed;
    width: var(--menu-width);

    // position: relative;

    &.full-screen {
      // grid-area: 1 / 1 / 3 / 3;
      width: 100vw;
    }
  }

  @media (prefers-reduced-motion) {
    animation-name: fadeIn;
    // animation-duration: 850ms;
  }
}

.cm-menu-contents-wrapper {
  padding-right: 8px;
  box-sizing: border-box;
  height: calc(100vh - var(--nav-height));
  max-height: calc(100vh - var(--nav-height));
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;

  // .App[data-hasparent="true"] & {
  //   padding-right: 0px;
  //   height: 100vh;
  //   max-height: 100vh;
  // }

  @media screen and (max-width: 800px) {
    padding-right: 12px;
  }
}

.cm-menu-inner-cont {
  @media screen and (max-width: 800px) {
    // width: var(--menu-width, 250px);
  }
}

// * =================================
// * TOP SECTION =====================
.cm-menu-top-cont {
  padding: 60px 21px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $mobile-top) {
    justify-content: space-between;
    padding-top: 34px;
  }
}

.cm-menu-user-info-wrapper-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cm-menu-logo-anchor-cont {
  width: 100%;

  @media screen and (min-width: $mobile-top) {
    display: none;
  }
}

.cm-menu-logo {
  max-width: 100%;
  max-height: 23px;
  object-fit: contain;
}

.cm-menu-user-info-cont {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media screen and (min-width: $mobile-top) {
    margin-bottom: auto;
    padding-top: 0px;
  }
}

.cm-menu-user-info-userthumb-cont {
}

.cm-menu-user-info-userthumb {
}

.cm-menu-user-info-userdata-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cm-menu-user-info-userdata-username {
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
}
// * TOP SECTION =====================
// * =================================
// *
// *
// * =================================
// * FOOTER SECTION ==================
.cm-menu-footer {
  margin-top: auto;
  padding: 0px 23px;
  box-sizing: border-box;
  width: 100%;
  // margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
}

.cm-menu-footer-logout-cont {
  width: 100%;
}

.cm-menu-build {
  font-size: 11px;
  opacity: 0.6;
}

.cm-menu-build-num {
  padding: 2px 3px;
  border-radius: 2px;
  background-color: var(--opaque-bg);
}

.cm-menu-footer-sitetheme-toggle {
  margin-left: auto;
  text-decoration: underline;
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }
}

.cm-menu-black-overlay {
  margin: 0px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  // background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 0px;
  left: var(--menu-width, 325px);
  height: 100%;
  width: calc(100% - 250px);
  min-width: calc(100vw - 250px);
  z-index: 9;
  animation: fadeIn 280ms ease-in-out 280ms;
  animation-fill-mode: backwards;
  // animation: waitToReveal 480ms ease-in-out;
  border-radius: 8px 0px 0px 0px;

  @media screen and (min-width: 801px) {
    display: none;
  }
}

// @keyframes  {

// }

.cm-menu-user-info-profile {
  text-decoration: none;
}
