@import "../../../styles/variables";

.cg-goalitems-goal-items-list {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.cg-goalitems-item-placeholder-msg {
  padding-left: 0px;
  margin-right: auto;
  font-size: 18px;
  color: var(--opaque-bg);
  font-weight: normal;
  font-style: italic;
  // flex-grow: 1;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 13px;
  }
}

// .cs-goalitems-item-cont {
//   padding: 0px 0px;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
// }

// .cg-goalitems-item-checkbox {
// }

// .cg-goalitems-item-input {
//   box-sizing: border-box;
//   flex-grow: 1;
//   max-width: calc(100% - 33px);
// }
