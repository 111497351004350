.interface-stats-title {
  margin: auto;
  width: fit-content;
  color: var(--white);
}

.interface-stats {
  height: fit-content;
}

.stats-heading-desktop {
  font-size: 40px;
  text-align: center;
}

.statistics-nav {
  margin-top: 30px;
}

.statistics-selection-cont {
  margin-top: 27px;
}

.statistics-selection-buttons {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;

  @media screen and (min-width: 801px) {
    display: flex;
    justify-content: center;
  }

  Button {
    display: inline-block;
    border-radius: 60px;
  }
}

.statistics-selection-buttons::-webkit-scrollbar {
  display: none;
}

.statistics-selection-teams {
  max-width: 500px;
  margin: auto;
  p {
    margin-top: 30px;
    margin-bottom: 4px;
    margin-left: 2px;
  }

  Select {
    margin-top: 5px;
  }
}

.statistics-selection-teams-select {
  width: 100%;
  height: 40px;
}

.statistics-display-pie {
  display: flex;
  margin-bottom: 40px;
}

.statistics-display-cont {
  margin-top: 15px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 700;

  @media screen and (min-width: 801px) {
    padding-bottom: 50px;
  }
}

.statistics-display-pie-activity {
  text-align: center;
}

.statistics-display-pie-activity.solo-doughnut {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-right: 35px;
  .statistics-display-pie-graph-text {
    display: flex;
    align-items: flex-end;
    h1 {
      font-size: 27px;
    }
    p {
      font-size: 15px;
    }
  }
}

.statistics-display-pie-created {
  height: 90px;
  width: 110px;
  color: var(--action);

  span {
    font-size: 30px;
  }
}

.statistics-pie-chart {
  width: 27px !important;
  height: 27px !important;
}

.statistics-display-pie-percent {
  display: flex;
  font-size: 30px;
  font-weight: 400;
  margin-top: 4px;

  sup {
    vertical-align: top;
    font-size: 12px;
    font-weight: 700;
    margin-right: 8px;
  }
}

.statistics-display-desc {
  margin-top: 12px;
  font-size: 1em;
  font-weight: 400;
  text-align: right;
  text-align: center;
  margin-bottom: 25px;
}

.statistics-display-pie-graphs {
  margin-bottom: 15px;
  min-height: 215px;
  @media screen and (min-width: 801px) {
    min-height: 274px;
  }
}

.statistics-display-pie-graphs.both-doughnut {
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 801px) {
    justify-content: center;
  }
}

.statistics-display-pie-graph-line {
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  @media screen and (min-width: 801px) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.statistics-display-button-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 90%;
  margin: auto;
  margin-bottom: 21px;
}

.statistics-selection-datepicker {
  margin-top: 20px;
}

.statistics-selection-start {
  margin-right: 150px;
  margin-left: 2px;
  margin-bottom: 4px;
}

.statistics-selection-teams-cascade {
  margin-top: 10px;
}

.statistics-main-nav {
  padding: 43px 0px 30px;
}

.interface-stats-loading-data {
  width: fit-content;
  margin: 50px auto;
}

@keyframes loading {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.interface-stats-loading-data-icon {
  animation-name: loading;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.statistics-display-color-key {
  display: grid;
  grid-template-columns: 30px 90%;
}

.statistics-display-color-box {
  height: 18px;
  width: 18px;
  background-color: white;
  margin-bottom: 14px;
}

.statistics-display-color-text {
  margin-top: 3px;
  font-weight: 700;
}

.sdcb-blue {
  background-color: var(--action);
}

.statistics-graph-selection {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  Button {
    display: inline-block;
    border-radius: 60px;
  }
}

.statistics-display-pie-graph-goalsCreated {
  text-align: center;
}

.statistics-display-pie-graph-goalsCreated.solo-doughnut {
  margin-left: 35px;
  height: 213px;
  width: 213px;
  display: flex;
  .statistics-display-pie-graph-text {
    display: flex;
    align-items: flex-end;
    h1 {
      font-size: 27px;
    }
    p {
      font-size: 15px;
    }
  }
}

.solo-doughnut-desktop {
  display: flex;
  .statistics-display-pie-graph-text {
    h1 {
      font-size: 27px;
    }
    p {
      font-size: 15px;
    }
  }
}
.statistics-display-pie-graph-text {
  margin-top: 25px;
  h1 {
    font-weight: 700;
    font-size: 20px;
  }
}

.donut-text-cont {
  fill: #ffffff;
}

.donut-percent {
  // font-size: 36px;
  line-height: 1;
  transform: translateY(0.5em);
  font-weight: bold;
}

.donut-text {
  // font-size: 9px;
  line-height: 1;
  transform: translateY(0.5em);
  text-align: center;
  text-anchor: middle;
}

.blur-filter {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}
