@import "../../../../styles/variables";

.document-viewer-encoding-not-found {
  height: 95vh;
  width: 95vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.document-viewer-encoding-not-found-msg {
  text-align: center;
}
