@import "../../../../styles/variables";

.plan-createnew-goal {
  // --_theme: transparent;
  --_theme: var(--font-color);

  // height: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;

  &.personal {
    --_theme: var(--accent-ppf-personal);
  }

  &.professional {
    --_theme: var(--accent-ppf-professional);
  }

  &.financial {
    --_theme: var(--accent-ppf-financial);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    height: 100%;
  }
}

.plan-newgoal-details-form {
  padding: 0px 12px;
}

.plan-newgoal-form-btn {
  margin-top: auto;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
}
