@import "/src/styles/variables";

.toggle-switch {
  position: relative;
  width: 40px;
  display: inline-block;
  @media screen and (max-width: $mobile-breakpoint) {
    width: 20px;
  }
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  height: 15px;
  @media screen and (max-width: $mobile-breakpoint) {
    border-radius: 5px;
    height: 7.5px;
  }
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  box-sizing: border-box;
  @media screen and (max-width: $mobile-breakpoint) {
    height: 10px;
  }
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: var(--action);
  @media screen and (max-width: $mobile-breakpoint) {
    padding-left: 5px;
  }
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #818284;
  @media screen and (max-width: $mobile-breakpoint) {
    padding-right: 5px;
  }
}
.switch {
  display: block;
  width: 20px;
  height: 20px;
  margin: 5px;
  background: var(--white);
  position: absolute;
  top: -7px;
  bottom: 0;
  right: 15px;
  border-radius: 10px;
  transition: all 0.3s ease-in 0s;
  @media screen and (max-width: $mobile-breakpoint) {
    width: 10px;
    height: 10px;
    margin: 2.5px;
    top: -3.5px;
    right: 10px;
  }
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: -5px;
  @media screen and (max-width: $mobile-breakpoint) {
    right: -2.5px;
  }
}
