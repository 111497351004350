@import "../../../styles/variables";

.cc-router-btn {
  font-size: 14px;
  //   color: $action;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.cc-router-btn-icon {
  margin-right: 4px;
}
