.plan-newgoal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.plan-newgoal-header-left {
  // width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //   gap: 11px;
}

.plan-newgoal-header-subtitle {
  padding-left: 12px;
  box-sizing: border-box;
  font-size: 16px;
}

.plan-newgoal-header-title {
  font-size: 24px;
  width: 100%;
}

.plan-newgoal-header-right {
  width: 48px;
}
