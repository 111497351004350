.cc-btn {
  --_button-theme: var(--action);

  padding: 8px 14px;
  box-sizing: border-box;
  background-color: var(--_button-theme);
  border-radius: 3px;
  border: 0px solid var(--_button-theme);
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  transition: transform 180ms ease-in-out;

  &:disabled {
    opacity: 0.6;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:active {
    transform: scale(0.94);
  }

  &.action {
    --_button-theme: var(--action);
    // background-color: var(--action);
    // border-color: var(--action);
  }

  &.alert {
    --_button-theme: var(--alert);
    // background-color: var(--alert);
    // border-color: var(--alert);
  }

  &.success {
    --_button-theme: var(--success);
    // background-color: var(--success);
    // border-color: var(--success);
  }

  &.personal {
    --_button-theme: var(--accent-ppf-personal);
    // background-color: var(--accent-ppf-personal);
    // border-color: var(--accent-ppf-personal);
  }

  &.professional {
    --_button-theme: var(--accent-ppf-professional);
    // background-color: var(--accent-ppf-professional);
    // border-color: var(--accent-ppf-professional);
  }

  &.financial {
    --_button-theme: var(--accent-ppf-financial);
    // background-color: var(--accent-ppf-financial);
    // border-color: var(--accent-ppf-financial);
  }

  &[class*="white"] {
    --_button-theme: var(--white);
    color: var(--black-font);
  }

  &.blank {
    background-color: #29303c;
  }

  &.dark-grey {
    background-color: rgba(69, 68, 68, 1);
  }

  &.transparent {
    --_button-theme: rgba(255, 255, 255, 0);
  }

  &[class*="-ghost"] {
    background-color: transparent;
    border-width: 1px;
    color: var(--_button-theme);
  }

  &:hover {
    cursor: pointer;
  }

  &.btn-glow {
    filter: drop-shadow(0px 1px 3px rgba(255, 255, 255, 0.3));
  }
}

.cc-btn-hover-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;

  .cc-btn:disabled & {
    display: none;
  }

  &::after {
    content: "";
    width: 0px;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.12);
    border-radius: 50%;
  }

  &:hover::after {
    animation: growOut 280ms ease-in-out;
    // width: 100%;
    // height:
    width: 250px;
    height: 250px;
  }

  .larger-hover &:hover::after {
    animation: growOutLarge 280ms ease-in-out;
    // width: 100%;
    // height:
    width: 500px;
    height: 500px;
  }
}

@keyframes growOutLarge {
  0% {
    max-width: 0px;
    max-height: 0px;
  }
  100% {
    max-width: 100%;
    max-height: 500px;
  }
}

@keyframes growOut {
  0% {
    max-width: 0px;
    max-height: 0px;
  }
  100% {
    max-width: 100%;
    max-height: 250px;
  }
}
