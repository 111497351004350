.cc-progress-bar-wrapper {
	&.has-details {
		display: flex;
		align-items: center;
	}
}

.cc-progress-bar {
	background-color: var(--opaque-bg);
	height: 5px;
	border-radius: 3px;

	// &.personal {
	//   background-color: ;
	// }
}

.cc-progress-complete-bar {
	background-color: var(--action);
	height: inherit;
	border-radius: inherit;
	transition: width 480ms ease-in-out;

	@media (prefers-reduced-motion) {
		transition: none;
	}

	.cc-progress-bar.action & {
		background-color: var(--action);
	}

	.cc-progress-bar.alert & {
		background-color: var(--alert);
	}

	.cc-progress-bar.success & {
		background-color: var(--success);
	}

	.cc-progress-bar.personal & {
		background-color: var(--accent-ppf-personal);
	}

	.cc-progress-bar.professional & {
		background-color: var(--accent-ppf-professional);
	}

	.cc-progress-bar.financial & {
		background-color: var(--accent-ppf-financial);
	}
}

.cc-progress-bar-details {
	padding-left: 8px;
	box-sizing: border-box;
	opacity: 0.75;
}

.cc-progress-bar-details-count {
}

.cc-progress-bar-details-count {
}
