@import "../../../styles/variables";

.cc-asset-cont {
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  .cc-asset-cont-button-wrapper > & {
    padding: 0px;
  }
}

.cc-asset-cont-button-wrapper {
  margin: 12px 0px;

  &.button-style-reset {
    margin: 12px 0px;
  }
}

.cc-asset {
  &.cc-asset-image {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &.cc-asset-video {
    width: 100%;
    // max-height: 100%;
    background-color: $black;
    max-height: calc(100vh - 80px);
    // max-height: 500px;
    border-radius: 8px;

    @media screen and (max-width: 800px) {
      border-radius: 0px;
    }
  }

  &.cc-asset-url {
  }

  &.asset-error-msg {
    font-size: 14px;
    color: $alert;
    font-weight: bold;
  }

  &.zipped-asset-msg {
    text-align: center;
    padding: 20px 0px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
  }
}

.cc-asset-nopreview-msg {
  padding-top: 8px;
  box-sizing: border-box;
  font-size: 12px;
  opacity: 0.4;
  text-align: center;
}

.cc-asset-caption {
  padding: 4px 6px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;

  &.image-caption {
  }
}
