.profile-header-userthumb-wrapper {
  width: 100%;
  position: relative;
}

.profile-header-userthumb-upload-btn {
  border: none;
  position: absolute;
  bottom: 0px;
  right: calc(225px / 5);
  padding: 2px;
  border-radius: 50%;
  background-color: var(--grey-light-bg);
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 800px) {
    right: calc(50% - 58px);
  }
}

.profile-header-userthumb-upload-btn-icon {
  fill: var(--black-font);
}
