@import "../../../styles/variables";
@import "../../../styles/animations";

.profile-header {
  padding: 50px 12px 8px;
  width: 100%;
  background: linear-gradient(to top, var(--opaque-bg) 0% 70%, transparent);
  border-bottom: 2px solid var(--opaque-bg);

  @media screen and (max-width: 800px) {
    padding-top: 20px;
  }
}

.profile-header-top {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
}

.one-third {
  flex-basis: calc(100% / 3);

  @media screen and (max-width: 800px) {
    flex-basis: 100%;
  }
}

.profile-header-userthumb-cont {
  padding: 0px 12px;
  // display: flex;
}

.profile-header-userthumb {
  margin-left: auto;
  width: 100%;
  animation: slideFromLeft 480ms ease-in-out;

  @media screen and (max-width: 800px) {
    margin-right: auto;
  }
}

.profile-header-username {
  padding-top: 12px;
  padding-bottom: 34px;
  font-size: 46px;
  animation: fadeIn 600ms ease-in-out;

  @media screen and (max-width: 800px) {
    padding-bottom: 16px;
    font-size: 23px;
    text-align: center;
  }
}

.profile-header-tab-btns {
  padding: 6px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  animation: fadeIn 600ms ease-in-out;
}

.profile-header-tab-btns-inner-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }
}

.profile-header-tab-btn-wrapper {
  padding-bottom: 12px;
  border-bottom: 1px solid transparent;
  transition: border-color 180ms ease-in-out;

  &.active-tab {
    border-color: var(--action);
  }

  &:nth-of-type(n + 2) {
    margin-left: 35px;
  }

  @media screen and (max-width: 800px) {
    padding-bottom: 8px;
    font-size: 13px;

    &:nth-of-type(n + 2) {
      margin-left: 20px;
    }
  }
}

.profile-header-tab-btn {
  padding-left: 0px;
  padding-right: 0px;
  color: var(--font-color);
  text-decoration: none;
  font-size: inherit;

  .active-tab > & {
    color: var(--action);
  }
}
