.profile-tabs-information {
  padding: 20px 0px 40px;
}

.profile-tabs-information-save-wrapper {
  padding-top: 40px;
  width: calc(100% / 3);
  margin: 0px auto;

  @media screen and (max-width: 800px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.profile-tabs-information-save-btn {
  min-width: 180px;
  min-height: 38px;
}
