@import "../../../../styles/variables";

.plan-editgoal-wrapper {
  padding: 0px 10px;
  // breaks scrolling, adds two scrollbars
  // height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .cg-editgoal-cont {
    padding: 0px;
    padding-bottom: 10px;

    @media screen and (max-width: $mobile-breakpoint) {
      padding-bottom: 65px;
    }
  }

  @media screen and (min-width: $mobile-top) {
    padding: 0px;
  }
}

.plan-edit-blocker-wrapper {
  margin-top: 25%;
  text-align: center;
}

.plan-edit-blocker-btn {
  margin: 20px;
}
