@import "../../../../../styles/variables";

// * LIST STYLES
.currentgoal-notices-list-wrapper {
  width: calc(80% + 28px);
  margin: auto;
  overflow-y: scroll;
  @media screen and (max-width: $mobile-breakpoint) {
    overflow-y: auto;
  }
}
.currentgoal-notices-list,
.currentgoal-comments-list {
  padding-bottom: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.currentgoal-comments-list {
  padding-bottom: 0px;
  // ----> UserThumb Size + Gap between that and text
  padding-left: calc(34px + 12px);
}

.currentgoal-comments-list {
  padding-top: 15px;
}

// * MESSAGE STYLES
.notice-msg-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  animation: fadeIn 380ms ease-in-out;
}

.notice-msg-userthumb-cont {
  width: 34px;
}

.notice-msg-details-cont {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notice-msg-name {
  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 9px;
  }
}

.notice-msg-text-cont {
  padding-top: 6px;

  @media screen and (max-width: $mobile-breakpoint) {
    padding-top: 4px;
    font-size: 12;
  }
}

.notice-msg-text {
  font-size: 16px;
  white-space: pre-wrap;

  .focus-message & {
    color: var(--action);
  }

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 12px;
  }
}

.log-msg-text {
  font-style: italic;
}

.notice-msg-actions-cont {
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-size: 10px;
  opacity: 0.5;
  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 9px;
  }
}

.cg-goalstories-currentgoal-noticethreads-x-button {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}
