@import "../../../../../styles/variables";

.cg-goalstories-goalsgrid-card {
  --_anim_start_scale: 0.95;

  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  // max-width: 300px;
  aspect-ratio: 270 / 371;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  animation: growIn 260ms ease-in-out;
  text-decoration: none;
  //   height: 100%;

  &.no-image {
    background-color: var(--opaque-bg);
  }

  @media screen and (min-width: $mobile-top) {
    transition: transform 180ms ease-in-out;

    &:hover {
      transform: scale(1.03);
      // outline: 2px solid var(--white);
    }
  }
}

@keyframes growInCard {
  0% {
    opacity: 0;
    transform: scale(var(--_anim_start_scale, 0.95));
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(var(--_anim_scale, 1));
  }
}

.cg-goalstories-goalsgrid-card-timestamp-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cg-goalstories-goalsgrid-card-timestamp-wrapper {
  padding: 3px 6px;
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.25);

  &.recent-timestamp {
    background-color: var(--action);
  }
}

.cg-goalstories-goalsgrid-card-timestamp {
  //   margin-left: auto;
  font-size: inherit;
  //   color: var(--black-font);
}

.cg-goalstories-goalsgrid-card-title {
  margin-top: auto;
  padding-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 28px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  text-shadow: 0px 0px 1px var(--black-font);
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
}

.cg-goalstories-goalsgrid-card-status-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: nowrap;
  font-size: 14px;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 12px;
  }
}

.cg-goalstories-goalsgrid-card-status-left-cont,
.cg-goalstories-goalsgrid-card-status-right-cont {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
}

.cg-goalstories-goalsgrid-card-status-left-cont {
  align-items: flex-start;
}

.cg-goalstories-goalsgrid-card-status-right-cont {
  align-items: flex-end;
}

.cg-goalstories-goalsgrid-card-percent {
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;

  @media screen and (max-width: $mobile-breakpoint) {
    font-size: 14px;
  }
}

.cg-goalstories-goalsgrid-card-targetstatus {
}

.cg-goalstories-goalsgrid-card-duration {
  text-transform: uppercase;
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 10px;
  background-color: var(--white);
  color: var(--black-font);

  &[data-durationyears="-1"] {
    background-color: var(--warning);
  }

  //   &[data-theme="personal"] {
  //     color: var(--font-color);
  //     background-color: var(--accent-ppf-personal);
  //   }

  //   &[data-theme="professional"] {
  //     color: var(--font-color);
  //     background-color: var(--accent-ppf-professional);
  //   }

  //   &[data-theme="financial"] {
  //     color: var(--font-color);
  //     background-color: var(--accent-ppf-financial);
  //   }
}

.cg-goalstories-goalsgrid-parent-type {
  text-transform: capitalize;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
}
