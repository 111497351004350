// * NOTE!
// * None of these Anima

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: none;
  }
}

@keyframes hideWhileMenuSlidesIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slightSlideFromTop {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes adjustBodyFromLeft {
  0% {
    transform: translateX(calc(var(--menu-width, 250px) * -1));
    min-width: calc(100% + var(--menu-width, 250px));
  }
  100% {
    transform: translateX(0px);
    min-width: 100%;
    max-width: calc(100%);
  }
}

@keyframes adjustBodyFromLeftMobile {
  0% {
    transform: translateX(calc(var(--menu-width, 250px) * -1));
    min-width: 100vw;
  }
  100% {
    transform: translateX(0px);
    max-width: calc(100%);
  }
}

@keyframes adjustBodyToLeft {
  0% {
    transform: translateX(var(--menu-width, 250px));
    max-width: calc(100% - var(--menu-width, 250px));
  }
  100% {
    transform: translateX(0px);
    max-width: calc(100%);
  }
}

@keyframes subtleSlideDown {
  0% {
    opacity: 0;
    transform: translateY(-12px);
    pointer-events: none;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes subtleSlideUp {
  0% {
    opacity: 0;
    transform: translateY(12px);
    pointer-events: none;
  }
  85% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes waitToReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes alertShake {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes growFromLeft {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
