@import "../../../styles/variables";

.cg-editgoal-goal-steps-list {
  padding-top: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cs-editgoal-step-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  // margin-bottom: 20px;

  @media screen and (max-width: $mobile-breakpoint) {
    // margin-bottom: 13px;
  }
}

.cg-editgoal-step-checkbox {
}

.cg-editgoal-step-input {
  font-weight: 400;
  padding-left: 3px;
  box-sizing: border-box;
  flex-grow: 1;
  // max-width: calc(100% - 65px);
  border-bottom: 1px solid var(--opaque-bg);
  font-size: 14px;

  @media screen and (min-width: $mobile-top) {
    font-size: 18px;

    // &.input-seemless {
    //   border-bottom: 1px solid transparent;

    //   &:hover {
    //     border-radius: 0px;
    //     border-bottom-color: var(--opaque-bg);
    //   }
    // }
  }

  .cs-editgoal-step-cont & {
    padding-left: 3px;
  }

  &.input-seemless {
    &[value=""] {
      border-radius: 0px;
      border-bottom: 1px solid var(--opaque-bg);
    }
  }
}

.cg-editgoal-step-delete-icon {
  width: 20px;
  margin-bottom: 3px;

  @media screen and (min-width: $mobile-top) {
    width: 35px;
    opacity: 0.4;
    transition: opacity 180ms ease-in-out;
    margin-bottom: 0px;

    &:hover {
      opacity: 1;
    }
  }
}

.cs-editgoal-step-cont-inner {
  padding: 6px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--semi-opaque-bg);
  border-radius: 8px;
  width: 100%;
  // height: 50px;
  @media screen and (min-width: $mobile-top) {
    gap: 8px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    gap: 2px;
    padding: 3px 8px;
    // height: 20px;
    border-radius: 3px;
    // padding-left: 6px;
  }
}

.cg-editgoal-step-cont-inner-mobile-adjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  flex-grow: 1;
}
