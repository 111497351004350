@import "../../../../styles/variables";

.cm-subnavs-back-nav {
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  [class*="cm-subnavs-back-nav-side-cont-"] {
    width: 20px;
  }
}

.cm-subnavs-back-nav-side-cont-left {
  @media screen and (min-width: $mobile-top) {
    display: none;
  }
}

.cm-subnavs-back-nav-cont-center {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cm-subnavs-back-nav-cont-center-title {
  margin: 0px auto;
  text-align: center;
  font-size: 16px;

  @media screen and (min-width: $mobile-top) {
    margin-left: 0px;
    font-size: 20px;
  }
}

.cm-subnavs-back-nav-side-cont-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;

  .cm-subnavs-back-nav & {
    width: auto;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    width: 20px;
  }
}

.cm-subnavs-back-nav-side-right-btn {
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 8px;

  @media screen and (max-width: $mobile-breakpoint) {
    display: none;
  }
}
