.parent-goal {
  padding: 20px 0px 20px 0px;

  ol,
  ul {
    padding: 12px;
    list-style: default;

    li {
      padding: 6px 0px 0px;
    }
  }
}
