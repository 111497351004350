.cc-icon-celebrate-shape {


    &.cc-icon-celebrate-color-1 {
        fill: var(--warning);
    }

    &.cc-icon-celebrate-color-2 {
        fill: var(--alert);
    }
    
    &.cc-icon-celebrate-color-3 {
        fill: purple;
    }

    &.cc-icon-celebrate-color-4 {
        fill: var(--success);
    }
    
    &.cc-icon-celebrate-color-5 {
        fill: var(--action);
    }
} 