// * Colors
$alert: #bc3430;
$warning: #f69b1e;
// $action: #940a0a;
$action: #0290ff;
$alt-action: #0290ff;
$success: #409432;
$alert-prog-bar: #eb3324;
$accent-ppf-personal: #c354f8;
$accent-ppf-professional: #0290ff;
$accent-ppf-financial: #4cd964;

$white: #ffffff;
$black: #000;
$white-font: #e0e0e0;
$black-font: #283747;
$red-bell: #ff0000;

$cv-blue: #1d325b;
$cv-mid: #1d325b;
$cv-blue-dark: #17233a;

// * Greys
$grey-dark-bg: rgba(196, 196, 196, 0.2);
$grey-light-bg: #c4c4c4;
$grey-border: #6c6c6c;
$grey-light: #ececec;
$grey-dark: #434343;

// * More Semantic aliases
$blue-cv: $cv-blue;
$blue-active: $action;

// * Gradiants
$transparent-to-black: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 1)
);
$transparent-to-white: linear-gradient(
  to bottom,
  rgba(0, 0, 0, 0),
  rgba(255, 255, 255, 1)
);
$purple-gradient: linear-gradient(
  to bottom,
  rgba(80, 66, 108, 0.6),
  rgba(80, 66, 108, 0)
);
$blue-gradient: linear-gradient(
  to bottom,
  rgba(29, 50, 91, 0.6),
  rgba(29, 50, 91, 0)
);
$dark-grey-gradient: linear-gradient(
  to bottom,
  rgba(23, 32, 42, 0.6),
  rgba(23, 32, 42, 0)
);
$grey-gradient: linear-gradient(
  to bottom,
  rgba(173, 173, 173, 0.6),
  rgba(173, 173, 173, 0)
);
$cv-dark-to-light: linear-gradient(
  158deg,
  rgba(0, 1, 41, 0.79),
  rgba(0, 1, 41, 0.22)
);
$cv-light-to-dark: linear-gradient(
  to bottom,
  rgba(0, 1, 41, 0),
  rgba(0, 1, 41, 0.79)
);
$ag-dark-to-light: linear-gradient(
  158deg,
  rgba(0, 1, 41, 0.79),
  rgba(0, 1, 41, 0.22)
);
$ag-light-to-dark: linear-gradient(
  to bottom,
  rgba(0, 1, 41, 0),
  rgba(0, 1, 41, 0.79)
);

// * Other Variables
$partially-transparent: 0.35;
$opaque-background: rgba(255, 255, 255, 0.12);
$opaque-background-dark: rgba(0, 0, 0, 0.12);
$semi-opaque-background: rgba(255, 255, 255, 0.28);
$semi-opaque-background-dark: rgba(0, 0, 0, 0.28);
$background-alert-transparent: #eb352467;
$background-success-transparent: rgba(30, 246, 78, 0.2);
$background-action-transparent: rgba(2, 144, 255, 0.2);
$background-warning-transparent: rgba(246, 155, 30, 0.4);
$good-points-background: $background-success-transparent;
$amazing-points-background: $background-warning-transparent;
$amazing-points-font-color: $warning;
$content-max-width: 1100px;
$mobile-breakpoint: 800px;
$mobile-top: $mobile-breakpoint + 1px;
$transition-time-ms: 180ms;
$title-font: "Poppins", sans-serif;
$nav-height: 70px;
