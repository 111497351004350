@import "../../../../../styles/variables";

.cm-menu-default-menu-hr {
  margin: 40px 20px 0px;
  width: calc(100% - 40px);
  outline: none;
  border: none;
  border-bottom: 1px solid;
  opacity: 0.4;
}

.cm-menu-default-menu {
  padding: 0px 0px 0px 12px;
  box-sizing: border-box;
  width: 100%;
}

.cm-menu-primary-links-list {
  margin-top: 35px;
  width: 100%;

  &.sub-list {
    margin: 4px 0px 4px 8px;
    padding-left: 10px;
    padding-right: 6px;
    border-left: 1px solid var(--border-color);
  }
}

.cm-menu-user-plan-link-list {
  margin-top: 35px;
  margin-bottom: 10px;
  width: 100%;

  &.sub-list {
    margin: 4px 0px 4px 8px;
    padding-left: 10px;
    padding-right: 6px;
    border-left: 1px solid var(--border-color);
  }
}

.cm-menu-genral-link-list {
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid var(--border-color);

  &.sub-list {
    margin: 4px 0px 4px 8px;
    padding-left: 10px;
    padding-right: 6px;
    border-left: 1px solid var(--border-color);
  }
}

.cm-menu-viewing-plan-link-list {
  margin-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid var(--border-color);

  &.sub-list {
    margin: 4px 0px 4px 8px;
    padding-left: 10px;
    padding-right: 6px;
    border-left: 1px solid var(--border-color);
  }
}

.cm-menu-default-menu-list-item {
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .sub-list & {
    font-size: 14px;
  }

  .is-disabled > & {
    opacity: 0;
  }

  .cm-menu-default-menu-list-item + & {
    margin-top: 8px;
  }
}

.cm-menu-default-menu-link {
  padding: 12px 14px;
  // padding: 0px;
  text-decoration: none;
  font-weight: bold;
  // width: 100%;
  flex-grow: 1;
  display: flex;

  .is-current-location & {
    color: var(--action);
  }

  .is-disabled > & {
    text-decoration: line-through;
  }
}

.cm-menu-default-menu-link-icon {
  margin-right: 8px;
}

.cm-menu-default-menu-link-disabled-msg {
  padding-right: 12px;
  font-size: 10px;
  font-weight: bold;
}

.cm-menu-default-hasparent-menu {
  padding: 20px 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  border-top: 1px solid var(--font-color);
  border-bottom: 1px solid var(--font-color);
  background-color: var(--opaque-bg);
  color: inherit;

  &:hover {
    cursor: pointer;
    background-color: var(--semi-opaque-bg);
  }
}

.cm-menu-default-hasparent-menu-icon {
  margin-right: 12px;
}

.cm-menu-default-menu-link-icon-right-cont {
  margin-top: 1px;
  margin-left: auto;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: var(--black);
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-menu-default-menu-link-icon-right {
}
