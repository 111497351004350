@import "../../../styles/variables";

.cc-userthumb-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: var(--opaque-bg);
  // background-color: transparent;
  background-color: rgba(0, 0, 0, 0.35);

  // .App.light-mode & {
  // .App[data-theme="light"] & {
  //   background-color: $opaque-background-dark;
  // }
}

.cc-userthumb {
  border-radius: 50%;
  object-fit: cover;
}

.cc-userthumb-no-img {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-size: 32px;
  color: $white;
  text-transform: uppercase;
}
