@import "../../styles/variables";

.interface-profile-main {
  @media screen and (max-width: 800px) {
    width: 100vw;
    min-width: 100vw;
  }
}

.profile-main-loading-msg {
  .cc-message {
    font-size: 24px;
    text-align: center;
  }
}

//Fixes the background color when a field is autocomplete
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px var(--bg-color) inset !important;
    -webkit-text-fill-color: #fff;
  }
}
