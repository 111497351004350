@import "../../../styles/variables";

.standard-react-select {
  & &__control {
    background-color: transparent;
    color: var(--font-color);
    border: 1px solid #ffffff;
    width: 175px;

    &:focus {
      border-color: var(--focus-outline-color);
    }

    &:hover {
      border-color: var(--focus-outline-color);
      cursor: pointer;
    }

    @media screen and (min-width: $mobile-top) {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      height: 24px;
      max-height: 24px;
      min-height: unset;
      width: 140px;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu {
    background-color: var(--bg-color);
    color: var(--font-color);
    height: fit-content;
    width: 175px;
    border: none;

    @media screen and (max-width: 500px) {
      margin: 0px;
      padding: 0px;
      padding-bottom: 6px;
      box-sizing: border-box;
      width: 140px;
      font-size: var(--input-font-size, 12px);
    }
  }

  & &__menu-option,
  & &__option {
    background-color: transparent;
    color: var(--font-color);

    @media screen and (max-width: 500px) {
      height: 16px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__single-value {
    color: var(--font-color);
    overflow: visible;
    margin-top: 2px;
  }

  & &__placeholder {
    margin-top: 0px;
    color: var(--font-color);
  }

  & &__indicator-separator {
    display: none;
  }

  & &__dropdown-indicator {
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
    box-sizing: border-box;

    .list-showing & {
      transform: rotate(180deg);
    }

    @media screen and (max-width: $mobile-breakpoint) {
      // margin-top: -5px;
      padding: 0px;
    }
  }

  & &__indicator-seperator {
    width: 0px;
  }

  & &__indicators-container {
    @media screen and (max-width: $mobile-breakpoint) {
      height: var(--input-font-size, 14px);
    }
  }

  & &__value-container {
    padding: 6px 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    min-height: 22px;
    box-sizing: border-box;

    @media screen and (min-width: $mobile-top) {
      margin-top: 2px;
    }

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0px;
      // height: var(--input-font-size, 14px);
    }
  }

  & &__input-container {
    margin: 0px;
    max-width: 5px;
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  & &__input {
    max-width: 5px;
  }
}
