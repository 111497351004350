@import "../../../../../styles/variables";

.cg-goalstories-goalsgrid {
  padding-top: 10px;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: auto;
  gap: 14px;

  .interface-wrapper.menu-open & {
    @media screen and (max-width: 1274px) {
      //   grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 999px) {
      //   grid-template-columns: repeat(2, 1fr);
    }
  }

  .interface-wrapper:not(.menu-open) & {
    @media screen and (max-width: 999px) {
      //   grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;

    .interface-wrapper:not(.menu-open) & {
      //   grid-template-columns: repeat(2, 1fr);
    }
  }
}
