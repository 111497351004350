@import "../../../../../styles/variables";

.modals-imagecropper-imagegrid-cont {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.modals-imagecropper-imagegrid {
  flex-grow: 1;
  gap: 3px;
  // max-height: calc(100% - 110px);
  overflow-y: auto;
  width: 100%;
}

.modals-imagecropper-imagegrid-inner-cont {
  // max-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
  width: 100%;
  margin-left: auto;
  @media screen and (min-width: $mobile-breakpoint) {
    width: 95%;
  }
}

.modals-imagecropper-imagegrid-tile {
  //   flex-basis: calc(100% / 3);
  flex-grow: 1;
  box-sizing: border-box;
  background-color: var(--opaque-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modals-imagecropper-imagegrid-tile-btn {
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc((100% / 4) - 9px);
  aspect-ratio: 1 / 1;

  &.is-selected {
    // outline: 2px solid var(--font-color);
  }

  @media screen and (max-width: 800px) {
    width: auto;
    // min-height: calc((100vw / 3) - 6px);
    min-height: 122px;
  }
}

.modals-imagecropper-imagegrid-selected-cont {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modals-imagecropper-imagegrid-selected-icon {
  fill: var(--white);
}

.modals-imagecropper-imagegrid-tile-img {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    width: 90px;
    height: 120px;
  }
}

.modals-imagecropper-imagegrid-tile-name {
  // TODO: Not sure how to display this yet
  display: none;
  font-size: 24px;
  font-weight: bold;
}

// .modals-imagecropper-imagegrid-btn-cont {
//   padding: 23px;
//   box-sizing: border-box;
//   width: 100%;
//   flex-grow: 1;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
// }

// .modals-imagecropper-imagegrid-btn {
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
// }

.modals-imagecropper-imagegrid-input-label {
  height: 50px;
  text-align: center;
}

.modals-imagecropper-imagegrid-input {
  opacity: 0;
  width: 0px;
  height: 0px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
