@import "../../../styles/animations";
@import "../../../styles/variables";

.cm-nav-cont {
  //   padding: 0px;
  padding: 16px 12px 16px 12px;
  box-sizing: border-box;
  width: 100%;
  //   grid-area: nav;
  grid-area: 1 / 1 / 2 / 3;
  max-width: 100vw;
  height: $nav-height;
  align-self: start;
  position: sticky;
  top: 0px;
  z-index: 11;
  background: var(--nav-header-bg);
  background-size: cover, cover;

  // .App[data-hasparent="true"] & {
  //   padding: 0px;
  //   grid-area: unset;
  //   position: fixed;
  //   top: 0px;
  //   left: 0px;
  //   width: 44px;
  //   height: 46px;
  //   border-radius: 0px 12px 12px 0px;
  //   border: 1px solid var(--font-color);
  //   background: var(--nav-header-bg);
  // }

  // .App[data-hasparent="true"] .interface-wrapper.menu-open & {
  //   left: 249px;
  //   // animation: slideMenuIconFromLeft 480ms ease-in-out;
  //   animation: hideWhileMenuSlidesIn 480ms ease-in-out;
  // }

  &.stick-to-top {
  }
}

.cm-nav-wrapper {
  padding: 20px 12px 16px 12px;
  box-sizing: border-box;
  width: 100%;
  //   grid-area: nav;
  max-width: 100%;
  //   align-self: center;
  height: $nav-height;
}

.cm-nav {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  min-height: 42px;
  //   z-index: 11;

  // .App[data-hasparent="true"] & {
  //   min-height: 46px;
  //   background-color: var(--opaque-bg);
  //   border-radius: inherit;

  //   .cm-nav-left {
  //     min-height: 46px;
  //     width: 100%;
  //   }

  //   .cm-nav-toggle-btn {
  //     flex-basis: 100%;
  //     height: 46px;
  //   }
  // }
}

// * Left Section ============
.cm-nav-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 34px;

  @media screen and (max-width: 800px) {
    flex-grow: 1;
    max-width: calc(100% - 80px);
  }
}

.cm-nav-toggle-btn {
  flex-basis: 32px;

  @media screen and (max-width: 800px) {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.cm-nav-toggle-btn-sidebar {
  flex-basis: 32px;

  @media screen and (max-width: 800px) {
    padding-top: 25px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.cm-nav-company-logo-anchor-cont {
  padding: 0px;

  &.searching {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

.cm-nav-company-logo,
.cm-nav-tryyb-logo {
  padding: 0px 6px;
  box-sizing: border-box;
  max-height: 23px;
  // max-height: 34px;

  padding-left: 10px;

  @media screen and (min-width: $mobile-top) {
    padding-left: 20px;
  }

  @media screen and (max-width: $mobile-breakpoint) {
    // display: none;
    max-width: calc(100% - 32px);
  }
}

.cm-nav-company-logo {
  padding: 0px 6px 0px 16px;
  box-sizing: border-box;
  max-height: 34px;
}

// * Middle Section ==========
.cm-nav-middle {
  padding: 0px 25px 0px 12px;
  box-sizing: border-box;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.cm-nav-center-btn {
  font-weight: bold;
  font-size: 14px;
  color: $action;

  &:hover {
    cursor: pointer;
  }
}

.cm-nav-search-cont {
  padding: 6px 10px;
  box-sizing: border-box;
  max-width: 275px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  margin-right: 20px;

  &.searching {
    // background-color: $white-font;

    .cm-nav-search {
      // fill: $black-font;
    }
  }
}

.cm-nav-search-input {
  color: var(--font-color);

  .cm-nav-search-cont & {
    margin: 0px 8px;
    width: 100%;
    border-radius: 0px;
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    // color: $black-font;
  }
}

.cm-nav-search-input-close-btn {
  // fill: $black-font;
}

// * Right Section ============
.cm-nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cm-nav-search {
  // margin-right: 20px;

  &.hide-search-icon {
    opacity: 0;
    pointer-events: none;

    &:hover {
      pointer-events: unset;
    }
  }

  @media screen and (max-width: 800px) {
    margin-right: 12px;
  }
}

.cm-nav-points {
  @media screen and (max-width: 800px) {
    font-size: 12px;
  }
}

.cm-nav-notifications-bell {
  margin: 0px 10px 0px 20px;
}

.cm-nav-menubtn {
  padding-right: 0px;

  .cm-nav-cont & {
    margin-right: 12px;
    padding: 0px;
    border: none;
    border-radius: 3px;
    background-color: transparent;
  }
}

// [data-reach-menu-list].cm-nav-menubtn-menulist {
// 	min-width: 120px;
// 	background-color: rgba(0, 0, 0, 0.15);
// 	color: $white-font;

// 	@media screen and (max-width: 800px) {
// 		background-color: transparent;
// 		color: var(--bg-color);
// 	}
// }

// [data-reach-menu-link].cm-nav-menubtn-menuitem,
// [data-reach-menu-item].cm-nav-menubtn-menuitem {
// 	padding: 6px 10px;
// 	color: inherit;
// 	background-color: rgba(0, 0, 0, 0.25);
// 	font-family: var(--font-family);

// 	&.is-link {
// 		display: block;
// 	}

// 	&:hover {
// 		background-color: rgba(0, 0, 0, 0.7);
// 		cursor: pointer;
// 	}

// 	@media screen and (max-width: 800px) {
// 		background-color: transparent;

// 		&:hover {
// 			background-color: initial;
// 		}

// 		&:active {
// 			background-color: rgba(0, 0, 0, 0.7);
// 		}
// 	}
// }
