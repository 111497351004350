@import "../../../styles/variables";

.cc-link {
  margin: 0px;
  padding: 3px 6px;
  box-sizing: inherit;
  background-color: transparent;
  color: var(--text-color);
  font-size: inherit;

  &.fake-disabled {
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.is-button {
    border: 0px;
    background-color: transparent;
  }
}
