//Styles for Permissions

//Flex All Parent - Column
.perm-flex-wrapper {
  padding: 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

//Flex Single Parent - Row
.perm-top-user-info-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
//selection override
.perm-top-user-info-cont .cm-subnavs-back-nav {
  width: 50px;
  margin-left: 10px;
}

//* TOP
.perm-thumbnail {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
}

.perm-description-text {
  width: 95%;
  font-size: 18px;
  padding: 10px 10px 10px 20px;
  text-align: center;
}

//* Members - Section
.perm-inner-cont {
}
.perm-members-section {
  height: 100%;
  padding: 0 10px 10px 20px;
}

.perm-members {
  margin-top: 40px;
  text-align: center;
}

.perm-member-card {
  padding: 15px 0px;
  //   max-width: 375px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 16px;
  border-bottom: var(--opaque-bg) 1px solid;
}
.perm-remove-link {
  font-weight: 400;
  position: relative;
  float: right;
  text-align: right;
  font-size: 16px;
  color: var(--alert-prog-bar);
}

.member-flex-column {
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: left;
}

//* Add User - Section - Row
.perm-add-user-cont {
  display: flex;
}

.perm-user-search-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 21px 0px 20px;
  width: 100%;
  box-sizing: border-box;
}

.perm-user-search-section {
}

.perm-add-user-section {
  height: 100%;
  padding: 5px 0px 10px 0px;
}

.perm-eyeglass {
  margin-top: 10px;
  margin-left: 20px;
  width: 30px;
}
.perm-add-user-text {
  margin-top: 12px;
  margin-left: 5px;
}

.perm-add-link {
  position: relative;
  float: right;
  text-align: right;
  font-size: 16px;
}

.perm-has-access {
  text-decoration: none;
  font-size: 10px;
  font-family: var(--font-family);
  color: var(--grey);
  text-align: left;
}

.perm-user-details {
  padding: 0px;
  text-decoration: none;
  font-size: 17px;
  font-family: var(--font-family);
  text-align: left;
  line-height: 16px;
}

.flex-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex-details-add {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.perm-add-user-search-card {
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 16px;
  &:nth-of-type(n) {
    border-bottom: var(--opaque-bg) 1px solid;
  }
}

@media screen and (max-width: 800px) {
  .hide-in-mobile {
    display: none;
  }
}

//* Desktop Overrides
@media screen and (min-width: 801px) {
  .perm-thumbnail {
    display: inline-block;
    margin-right: 10px;
  }
  .perm-plan-name {
    text-align: left;
  }
  .hide-in-desktop {
    display: none;
  }
  .perm-top-user-info-cont {
    width: 95%;
    justify-content: space-between;
  }
  .perm-members-section {
    width: 100%;
  }
  .perm-member-card {
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
  }
  .perm-card-wrapper {
    display: flex;
  }
}

.perm-error {
  font-size: 0.7em;
  font-weight: 300;
  color: var(--opaque-bg);
  margin-top: 5px;
}

.perm-error-search-user {
  font-size: 1em;
  font-weight: 300;
  color: var(--opaque-bg);
  padding: 3px;
  text-align: center;
}
