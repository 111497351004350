.cc-asset-url {
  text-decoration: none;
  color: var(--font-color);
}

.cc-url-block-style-link {
  margin-right: auto;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--opaque-bg);
  border: 1px solid var(--semi-opaque-bg);
  border-radius: 8px;
  color: inherit;

  .cc-url-full-style-link > & {
    border-radius: 0px 0px 8px 8px;
    max-width: 100%;
  }

  .course-section.completed-section & {
    background-color: var(--success-opaque);
    border-color: var(--success-opaque);

    .cc-url-full-style-link:hover & {
      background-color: var(--success-opaque);
    }

    &:hover {
      background-color: var(--success-opaque);
    }
  }

  .cc-url-full-style-link:hover & {
    background-color: var(--semi-opaque-bg);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--semi-opaque-bg);
  }
}

.cc-asset-url-block-style-link-label {
  padding: 0px 16px;
  box-sizing: border-box;
  flex-grow: 1;
  color: inherit;
  font-size: 22px;

  @media screen and (max-width: 800px) {
    font-size: 16px;
    max-width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cc-url-full-style-link {
  margin-right: auto;
  width: 100%;
  max-width: 750px;
  border-radius: 8px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.cc-url-full-style-link-image-cont {
  max-height: 750px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--opaque-bg);
  position: relative;
  color: var(--font-color);
}

.cc-url-full-style-link-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--semi-opaque-bg-inverse);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 280ms ease-in-out;

  .cc-url-full-style-link:hover & {
    opacity: 1;
  }
}

.cc-url-full-style-link-overlay-icon {
}

.cc-url-full-style-link-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 280ms ease-in-out;

  .cc-url-full-style-link:hover & {
    transform: scale(1.04);
  }
}

.cc-url-image-style-link {
  max-height: 100%;
  max-width: 100%;
}

.cc-url-image-style-link-image {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
